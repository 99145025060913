import { Button, Result } from 'antd';
import React from 'react';
import { useError } from './useError';
import { withRouter } from 'react-router';

const Error: React.FC = () => {
	const { back } = useError();

	return (
		<Result
			status="404"
			title="404"
			subTitle="Upps there is something wrong here"
			extra={<Button onClick={back} type="primary">Back</Button>}
		/>
	);
};

export default withRouter(Error);
