import React from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import './ResetButton.scss'

const ResetButton = ({onClick}) => {
  return (
    <div className="reset-buton">
      <Tooltip title="Reset filter">
        <Button
          onClick={onClick}
          type="primary"
          shape="circle"
          icon={<ReloadOutlined />}
          size={"large"}
        />
      </Tooltip>
    </div>
  );
};

export default ResetButton;
