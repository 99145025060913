import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface UseErrorStore {
	back: () => void;
}

export const useError = (): UseErrorStore => {
	const history = useHistory();

	const back = (): void => {
		history.push('/');
	};

	React.useEffect(() => {
		Mixpanel.track('error', {
			type: 404,
		});
	}, []);

	return {
		back,
	};
};
