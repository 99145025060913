import React, { useState, useEffect } from "react"
import { Row, Col, Typography, message } from "antd"
import Moment from 'react-moment'
import { useRemoteConfig } from 'reactfire';
import { useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

const HeaderImageRowCollapse = ({index, image}) => {
  const { id } = useParams();
  const { Text, Link } = Typography
  const [ urlImageBank, setUrlImageBank ] = useState('')

  const {
    edited_by, 
    edit_date,
    creation_date
  } = image

  const remoteConfig = useRemoteConfig();

	remoteConfig.defaultConfig = {
		'function_image_bank_url': 'https://us-central1-simile-analyst-tool-5aaae.cloudfunctions.net/simileImageBank',
	};

	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

useEffect(() => {

		async function fetchData() {		

      console.log(image.idFirebase);
      console.log(id);

			try {
				await remoteConfig.fetchAndActivate();
			} catch (err) {
				console.error(err);
			}      

      let remoteUrl = remoteConfig.getString('function_image_bank_url');

      setUrlImageBank(remoteUrl);
		}

		fetchData();

	}, []);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(`${urlImageBank}?id=${image.idFirebase ?? id}`);
    message.success('Your link was copied to the clipboard');        
  }

  return (
    <div>
      <Row>
      <Col span={6}>
          <Text type="secondary" className="subtitle">
            DATE CREATED
          </Text>
          <div>
            <Text strong><Moment format="YYYY/MM/DD">{creation_date && creation_date.seconds * 1000}</Moment></Text>
          </div>
        </Col>  
      <Col span={6}>
          <Text type="secondary" className="subtitle">
            Last Edit
          </Text>
          <div>
            <Text strong><Moment format="YYYY/MM/DD">{edit_date && edit_date.seconds * 1000}</Moment></Text>
            <Text type="secondary"> by </Text>
            <Text strong>{edited_by}</Text>
          </div>
        </Col>          
        <Col span={12}>
          <Text type="secondary" className="subtitle">
            Image Bank URL 
          </Text>
          <div>
          <Link onClick={copyToClipboard} target="_blank">
              {image.name} <CopyOutlined />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderImageRowCollapse;
