import { Col, message, Modal, Row, Space, Typography } from 'antd';
import { MessageOutlined, StarFilled, StarOutlined, StarTwoTone } from '@ant-design/icons';
import React, { Fragment, useContext, useEffect, useState} from 'react';
import { Status, StatusType } from '../../data/Status';
import IconSelect from '../Select/IconSelect';
import { Link } from 'react-scroll';
import Moment from 'react-moment';
import { RatingsType } from '../../data/Rating';
import { UpdateProjectEdition } from '../../tools/UpdateProjectEdition';
import { useFirestore } from 'reactfire';
import { UserContext } from '../../providers/UserProvider';

const StatusHeader = (props) => {
	const { style, response, comments } = props;
	const { Text } = Typography;
	const [value, setValue] = useState('');
	const firestore = useFirestore();
	const [visible, setVisible] = useState(false);
	const { user } = useContext(UserContext);
	const [hover, setHover] = useState(false);
	const [proposedValue, setProposeValue] = useState('');
	const [disableStatus, setDisableStatus] = useState(true);
	const id = response.idFirebase;

	const openNotification = type => {
		message.success(`${type} Saved`);
	};

	useEffect(() => {
		if (response.simile_status) {
			setValue(response.simile_status);
		} else {
			firestore.collection('responses')
				.doc(id)
				.update({'simile_status': StatusType.open})
				.then(() => {
					console.log('Document written with ID: ', id);
					setValue(StatusType.open);
				})
				.catch((error) => {
					console.error('Error updating document: ', error);
				});
		}
	}, [firestore, id, response]);

	useEffect(() => {
		setDisableStatus(response.simile_rating !== RatingsType.usable);
	}, [response.simile_rating]);

	const setStarred = (e) => {
		e.stopPropagation();

		let valueStarred = !response.simile_starred;

		if (typeof response.simile_starred === 'undefined') {
			valueStarred = true;
		}

		firestore.collection('responses')
			.doc(response.idFirebase)
			.update({'simile_starred': valueStarred, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', response.idFirebase);
				UpdateProjectEdition(firestore, user, response.surveyId);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});

	};

	const updateStatus = (value) => {
		firestore.collection('responses')
			.doc(id)
			.update({'simile_status': value, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', id);
				setValue(value);
				UpdateProjectEdition(firestore, user, response.surveyId);
				openNotification('Response Status');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setValue(Status[1].value);
			});
	};

	const handleChange = (newValue) => {
		if (newValue === StatusType.open && value === StatusType.completed) {
			setVisible(true);
			setProposeValue(newValue);

			return;
		}

		updateStatus(newValue);
	};

	const handleOk = () => {
		updateStatus(proposedValue);
		setVisible(false);
	};

	const handleCancel = (e) => {
		console.log(e);
		setVisible(false);
	};

	return (
		<div className={'StatusHeader'} style={style}>
			<Row justify="space-between">
				<Col>
					<Space direction="vertical">
						<Text className="subtitle">
							STATUS
						</Text>
						<IconSelect disabled={disableStatus} value={value} handleChange={handleChange} style={{width:'124px', marginTop: '-5px', marginBottom: '5px'}} options={Status} />
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Text className="subtitle">
							DATE CREATED
						</Text>
						<Text strong>
							<Moment format="YYYY/MM/DD">{response.startDate}</Moment>
						</Text>
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Text className="subtitle">
							LAST EDIT
						</Text>
						<Text strong>
							{typeof response.edit_user != 'undefined' && (
								<Fragment>
									<Moment format="YYYY/MM/DD">{response.edit_date && response.edit_date.seconds * 1000}</Moment>
									{` by ${response.edit_user}`}
								</Fragment>
							)}
						</Text>
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Text className="subtitle">

						</Text>
						<Text strong>

						</Text>
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Text className="subtitleq">
							Response ID
						</Text>
						<Text strong>
							{response.id}
						</Text>
					</Space>
				</Col >
				<Col>
					<Space direction="horizontal">
						<MessageOutlined />
						<Link to="CommentsResponse" spy={true} smooth={true} offset={-100}>
							{comments.length} comments
						</Link>
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Link onClick={setStarred}>
							<div className="starred-icon" onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)}>
								{response.simile_starred ?
									<StarFilled style={{ color: '#FFA524'}} />
									:
									<Fragment>
										{hover ?
											<StarTwoTone twoToneColor="#FFA524" />
											:
											<StarOutlined style={{ color: '#C0BDBD'}}/>
										}
									</Fragment>
								}
							</div>
						</Link>
					</Space>
				</Col>
			</Row>

			<Modal
				title="Status change request"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Yes, change it "
				cancelText="No, cancel"
			>
				<Text align="center">
					<p>Are you sure you want to change the <br/>
					status of the response <strong>{response.id}</strong> to <strong>{proposedValue}</strong></p>
				</Text>
			</Modal>
		</div>
	);
};

export default StatusHeader;
