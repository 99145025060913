import React, { Fragment, useContext, useState, useEffect } from "react"
import Collasable from "../Collasable/Collasable"
import { CommentContext } from "../../providers/CommentProvider"
import CommentCard from "../../components/CommentCard/CommentCard"
import { useParams } from 'react-router-dom';
import CommentList from '../CommentList/CommentList';
import CommentInput from '../CommentInput/CommentInput';
import { Divider, Comment, Avatar } from 'antd';
import { useFirestore } from "reactfire"
import moment from 'moment';
import { UserContext } from '../../providers/UserProvider';

const ProjectComments = (props) => {
    const { style, comments, query, project} = props
    const { id } = useParams()
    const firestore = useFirestore()
    const { user } = useContext(UserContext)
    const [sortedComments, setSortedComments] = useState([])

    const addComment = (comment) => {
        firestore.collection('projects')
        .doc(id)
        .collection('comments')
        .add({
            author: user.name,
            author_initials: user.initials,
            author_color: user.color,
            content: comment,
            datetime: new Date()
        })
        .then(() => {
            console.log("Document written with ID: ", id)
        })
        .catch((error) => {
            console.error("Error updating document: ", error)
        })  
    }

    const loadSortedComments = () => {
        const sorted = comments.sort(function(a,b){
            return new Date(b.datetime.seconds) - new Date(a.datetime.seconds);
        });
        setSortedComments(sorted)
    }

    useEffect(() => {
        loadSortedComments()
    }, [comments])

    return (
        <div className={`ResponseComments ${project.simile_status != 'Active' ? 'disabled-container' : ''}`} style={style}>
                <CommentInput addComment={addComment}/>
                {sortedComments.length > 0 &&
                    <Fragment>
                        <Divider />
                        <CommentList query={query} comments={sortedComments} />
                    </Fragment>
                }      
        </div>
    )
}

export default ProjectComments