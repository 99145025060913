import { Col, message, Radio, Row, Space, Typography } from 'antd';
import { FrownFilled, MehFilled, SmileFilled } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Sentiments, SentimentsType } from '../../data/Sentiments';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { RatingsType } from '../../data/Rating';
import { UpdateProjectEdition } from '../../tools/UpdateProjectEdition';
import { UserContext } from '../../providers/UserProvider';

const RatingStatus = ({response, id}) => {
	const { Text } = Typography;
	const [sentimentValue, setSentimentValue] = useState();
	const [ratingValue, setRatingValue] = useState();
	const { user } = useContext(UserContext);

	const firestore = useFirestore();
	const queryAdditionals = firestore.collection('responses').doc(id).collection('additionals_metaphors');
	const additionals = useFirestoreCollectionData(queryAdditionals, {idField: 'idFirebase'});

	const openNotification = type => {
		message.success(`${type} Saved`);
	};

	const onSentimentChange = (e) => {
		firestore.collection('responses')
			.doc(id)
			.update({ 'simile_sentiment': e.target.value, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid })
			.then(() => {
				console.log('Document written with ID: ', id);
				setSentimentValue(e.target.value);
				openNotification('Sentiment');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setSentimentValue('');
			});
	};

	const onRatingChange = (e) => {
		const objectToUpdate = { 'simile_rating': e.target.value, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid };

		if (e.target.value === RatingsType.notUsable || e.target.value === RatingsType.missing) {
			objectToUpdate['simile_metaphor'] = '';
			objectToUpdate['simile_thematicpmetaphor'] = '';
			objectToUpdate['additionals_metaphors'] = [];
		}

		firestore.collection('responses')
			.doc(id)
			.update(objectToUpdate)
			.then(() => {
				console.log('Document written with ID: ', id);
				additionals.forEach(additional => {
					firestore.collection('responses')
						.doc(id)
						.collection('additionals_metaphors')
						.doc(additional.idFirebase)
						.delete()
						.then(() => {
							console.log('Documents successfully deleted!');
						}).catch((error) => {
							console.error('Error removing document: ', error);
						});
				});
				openNotification('Rating Sentiment');
				setRatingValue(e.target.value);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setRatingValue('');
			});
	};

	useEffect(() => {
		UpdateProjectEdition(firestore, user, response.surveyId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sentimentValue, ratingValue]);

	const getSentiment = (value) => {
		if (typeof value != 'undefined') {
			const current = Sentiments.filter(obj => obj.value.toLowerCase() === value.toLowerCase());

			if (current.length > 0) {
				return current[0].value;
			}

			return '';
		}

		return '';
	};

	return (
		<div className="RatingStatus" style={{ marginTop: '10px'}}>
			<Row className="sentiments-rating-box" align="middle" justify="start">
				<Col style={{ marginRight: '122px'}}>
					<Space size={12}>
						<Text className="subtitle" style={{marginLeft: '11px', marginRight: '18px'}}>SENTIMENT</Text>
						<Radio.Group value={getSentiment(response.simile_sentiment ?? response.sentimentLabel)} onChange={onSentimentChange}>
							<Radio value={SentimentsType.positive}><SmileFilled style={{ color: '#4CB865', marginRight: '5px'}} />{SentimentsType.positive}</Radio>
							<Radio value={SentimentsType.neutral}><MehFilled style={{ color: '#979797', marginRight: '5px'}} />{SentimentsType.neutral}</Radio>
							<Radio value={SentimentsType.negative}><FrownFilled style={{ color: '#E84F3D', marginRight: '5px'}} /> {SentimentsType.negative}</Radio>
						</Radio.Group>
					</Space>
				</Col>
				<Col>
					<Space size={12}>
						<Text className="subtitle" style={{marginLeft: '11px', marginRight: '18px'}}>RATING</Text>
						<Radio.Group value={response.simile_rating} onChange={onRatingChange}>
							<Radio value={RatingsType.usable}>{RatingsType.usable}</Radio>
							<Radio value={RatingsType.notUsable}>{RatingsType.notUsable}</Radio>
							<Radio value={RatingsType.missing}>{RatingsType.missing}</Radio>
						</Radio.Group>
					</Space>
				</Col>
			</Row>
		</div>
	);
};

export default RatingStatus;
