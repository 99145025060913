import React from 'react'
import { Typography, Space, Row, Col, Switch } from 'antd'

const CollasableHeader = (props) => {
    const { Title, Text } = Typography
    const {onChange, defaultChecked, title, subtitle} = props

    return (
        <div className="box-content">
            <Row justify="space-between" align="middle">
                <Col>
                    <Title style={{marginTop: "8px"}} level={4}>{title}</Title>                                                                     
                </Col>
                <Col>
                    <Space direction="horizontal">
                        <Text strong>{subtitle}</Text>
                        <Switch defaultChecked={defaultChecked} onChange={onChange} />                          
                    </Space>                                                      
                </Col>                    
            </Row>   
        </div> 
    )
}

export default CollasableHeader
