import { useFirestore, useFirestoreCollectionData, useRemoteConfig } from 'reactfire';
import { Analyst } from '../../interfaces/Analyst';
import axios from 'axios';
import { Image } from '../../interfaces/Image';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

interface UseCreateProjectStore {
    handleCancel: () => void;
    handleOk: () => void;
    letters: number;
    loadingButton: boolean;
    onCancel: () => void;
    onFinish: (value: any) => void;
    onChange: (value: any[]) => void;
    questions: number;
    setLetters: (value: number) => void;
    visibleModal: boolean;
    loading: boolean;
    filterAnalyst: (input: string, option: any) => boolean;
	filterProject: (input: string, option: any) => boolean;
    surveys: { data: any[]};
    showFields: boolean;
    analysts: Analyst[];
	imageBank: Image[];
}

export const useCreateProject = (): UseCreateProjectStore => {
	const history = useHistory();
	const [surveys, setSurveys] = React.useState({ data: [] });
	const [letters, setLetters] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [visibleModal, setVisibleModal] = React.useState(false);
	const [loadingButton, setLoadingButton] = React.useState(false);
	const [showFields, setShowFields] = React.useState(false);
	const [questions] = React.useState(0);

	const firestore = useFirestore();
	const queryAnalysts = firestore.collection('users');
	const analysts: Analyst[] = useFirestoreCollectionData(queryAnalysts);
	const { user } = React.useContext(UserContext);
	const remoteConfig = useRemoteConfig();

	const queryImageBanks = firestore.collection('images');
	const imageBank: Image[] = useFirestoreCollectionData(queryImageBanks, {idField: 'idFirebase'});

	remoteConfig.defaultConfig = {
		'qualtrics_function_surveys_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/surveys',
	};
	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'create-project',
		});
	}, []);

	React.useEffect(() => {
		async function fetchData(): Promise<void> {
			setLoading(true);

			try {
				await remoteConfig.fetchAndActivate();
			} catch (err) {
				console.error(err);
				setSurveys({ data: [] });
				setLoading(false);
			}

			try {
				const projectsFromFirebase = await firestore.collection('projects').get();
				const projects = projectsFromFirebase.docs.map(doc => doc.data());
				const result = await axios(
					remoteConfig.getString('qualtrics_function_surveys_url')
				);
				const data = projects.length > 0 ? result.data.filter((obj: {id: string}) => !projects.find(project => project.survey == obj.id) ) : result.data;

				const sorted = data.sort((a: {creationDate: string}, b: {creationDate: string}) => {
					return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
				});

				setSurveys({ data: sorted });

				setLoading(false);
			} catch (err) {
				console.error('Error fetching surveys from Qualtrics', err);
				setSurveys({ data: [] });
				setLoading(false);
			}
		}

		fetchData();
	}, [firestore, remoteConfig]);

	const onCancel = (): void => {
		if (showFields) {
			setVisibleModal(true);
		} else {
			history.push('/');
		}
	};

	const handleOk = (): void => {
		history.push('/');
	};

	const handleCancel = (): void => {
		setVisibleModal(false);
	};

	const onChange = (value: any[]): void => {
		if (value.length > 0) {
			setShowFields(true);
			localStorage.setItem('projectEdited', new Boolean(true).toString());
		} else {
			setShowFields(false);
			localStorage.setItem('projectEdited', new Boolean(false).toString());
		}
	};

	const onFinish = (values: any): void => {
		setLoadingButton(true);
		const survey: any[] = surveys.data.filter((survey: any) => survey.id == values.survey);

		values.name = survey[0].name;
		values.creation = survey[0].creationDate;
		values.status = survey[0].isActive ? 'Active' : 'Inactive';
		values['edited_date'] = new Date();
		values['edited_by'] = user.name;
		values.coded = 0;
		values.discarded = 0;
		values.notes = values.notes ?? '';
		values['raw_questions'] = survey[0].rawQuestions;

		const questions: any[] = [];
		const questionsKey = Object.keys(survey[0]).filter(key => key.startsWith('linkSelectedQ'));

		questionsKey.forEach(key => {
			const question = survey[0][key];

			questions.push(question);
		});
		values.questions = questions;

		firestore.collection('projects')
			.doc(values.survey)
			.set({ ...values, analyst: [ ...values.analyst, user.email ] })
			.then(() => {
				history.push(`/project/${values.survey}`);
				setLoadingButton(false);
			})
			.catch((error) => {
				console.error('Error creating project', error);
				setLoadingButton(false);
			});
	};

	const filterAnalyst = (input: string, option: any): boolean => {
		return option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	const filterProject = (input: string, option: any): boolean => {
		return option.children.props.children[0].props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	return {
		loading,
		onFinish,
		onChange,
		filterAnalyst,
		filterProject,
		surveys,
		showFields,
		setLetters,
		analysts: analysts.filter(analyst => analyst.email !== user.email),
		onCancel,
		loadingButton,
		visibleModal,
		handleOk,
		handleCancel,
		letters,
		questions,
		imageBank,
	};
};
