import './ResponseDetail.scss';
import { Element } from 'react-scroll';
import { Layout } from 'antd';
import OthersResponses from '../../components/Response/OthersResponses';
import React from 'react';
import Response from '../../components/Response/Response';
import ResponseComments from '../../components/Response/ResponseComments';
import ResponseHeader from '../../components/Response/ResponseHeader';
import ResponsesPreview from '../../components/ResponsesPreview/ResponsesPreview';
import SimileHeader from '../../components/Header/SimileHeader';
import StatusNavigation from '../../components/Response/StatusNavigation';
import { useResponseDetail } from './useResponseDetail';
import { withRouter } from 'react-router';

const ResponseDetail: React.FC = () => {
	const { Content } = Layout;
	const {
		response,
		tooglePreview,
		project,
		comments,
		allResponses,
		commentsQuery,
		isPreviewShow,
	} = useResponseDetail();

	return (
		<Layout className="layout" style={{ paddingTop: '64px' }}>
			<SimileHeader />
			<Content className={'container'}>
				<ResponseHeader response={response} tooglePreview={tooglePreview} project={project} />
				<Response comments={comments} response={response} project={project} style={{ marginBottom: '10px' }} />
				<StatusNavigation project={project} response={response} style={{ marginBottom: '20px' }} />
				<Element name="Comments">
					<ResponseComments project={project} query={commentsQuery} comments={comments} response={response} style={{ marginBottom: '20px' }} />
				</Element>
				<OthersResponses response={response} project={project} />
			</Content>
			<ResponsesPreview responses={allResponses} tooglePreview={tooglePreview} show={isPreviewShow} />
		</Layout>
	);
};

export default withRouter(ResponseDetail);
