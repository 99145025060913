import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';

export const useRedirect = (): void => {
	const { project, page }: { project: string; page: string} = useParams();
	const firestore = useFirestore();
	const responseQuery = firestore.collection('responses').where('surveyId', '==', project);
	const response = useFirestoreCollectionData(responseQuery, {idField: 'idFirebase'});
	const history = useHistory();

	React.useEffect(() => {
		const getResponseAndRedirect = async (): Promise<void> => {
			const resp = response.filter(obj => obj.simile_index == page);

			if (resp.length > 0) {
				history.replace(`/response/${resp[0].idFirebase}`);
			}
		};

		getResponseAndRedirect();
	}, [history, page, response]);
};
