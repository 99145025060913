import React, { Fragment } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Collasable from '../Collasable/Collasable';
import LoopResponse from './LoopResponse';

const OthersResponses = ({ response, project }) => {
	const firestore = useFirestore();
	const relatedQuery = firestore.collection('responses').where('id', '==', response.id);
	const related = useFirestoreCollectionData(relatedQuery, {idField: 'idFirebase'});

	return (
		<div className="OthersResponses">
			<Collasable opened={false} subtitle="Show other responses" unbox title="Other responses by the same participant">
				{related.map((relatedResponse, index) => (
					<Fragment key={index}>
						{relatedResponse.idFirebase != response.idFirebase &&
							<LoopResponse idLink={`/redirect/${relatedResponse.surveyId}/${relatedResponse.simile_index}`} id={relatedResponse.idFirebase} response={relatedResponse} project={project} style={{ marginBottom: '10px'}} />
						}
					</Fragment>
				))}
			</Collasable>
		</div>
	);
};

export default OthersResponses;
