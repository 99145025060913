import React, {Fragment } from 'react';
import ImagesRowDetail from '../ImagesRowDetail/ImagesRowDetail';

const ImagesIndexList = ({images, bank}) => {
	if (images === null) {
		return <div></div>;
	}

	return (
		<Fragment>
			<div className="box-content" style={{marginBottom:'10px', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '26px', paddingRight: '26px'}} >
				<ImagesRowDetail
					image={images}
					bank={bank}
					index={true}
				/>
			</div>
		</Fragment>
	);
};

export default ImagesIndexList;
