import React, { Fragment, useState, useContext, useEffect } from "react";
import { Form, Button, Input, Comment, Avatar } from "antd";
import { CommentProvider } from "../../providers/CommentProvider";
import { UserContext } from '../../providers/UserProvider';

const CommentInput = ({addComment}) => {
  const { Item } = Form;
  const { TextArea } = Input;
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState("");
  const { user } = useContext(UserContext)

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    setSubmitting(true);

    setTimeout(() => {
      addComment(value)
      setSubmitting(false)
      setValue("")
    }, 1000)
  }

  return (
    <div>
      <Comment
        avatar={
          <Avatar style={{backgroundColor: user.color}}>{user.initials}</Avatar>
        }
        content={
          <Fragment>
            <Form>
              <Item>
                <TextArea
                  rows={3}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={submitting}
                onClick={handleSubmit}
              >
                Post Comment
              </Button>
            </Form>
          </Fragment>
        }
      />
    </div>
  );
};

export default CommentInput;
