import React, {useState} from 'react'
import ConvertHexToRGBA from '../../tools/ConvertHexToRGBA'

const Metaphor = (props) => {
    const {data, unselected, label, active, onMetaphorClick, color} = props
    const [hover, setHover] = useState(false);

    const metaphorClick = () => {
        if (typeof onMetaphorClick != 'undefined') {
            onMetaphorClick(data)
        }        
    }

    const thematicMetaphorClick = (value) => {
        if (typeof onMetaphorClick != 'undefined') {
            onMetaphorClick(value.innerHTML.toLowerCase())
        }
    }
    
    if (typeof label != 'undefined') {

        if (label != "") {
            const defautStyle = {
                borderColor: '#fff', 
                color: color, 
                backgroundColor: '#fff',             
            }

            const defautHoverStyle = {
                borderColor: ConvertHexToRGBA(`${color}`, 10),
                color: color, 
                backgroundColor: ConvertHexToRGBA(`${color}`, 10)            
            }            
    
            const activeStyle = {
                borderColor: color, 
                color: color, 
                backgroundColor: ConvertHexToRGBA(`${color}`, 10)
            }
    
            return <div onMouseEnter={()=>{ setHover(true) }} onMouseLeave={()=> { setHover(false) }} onClick={e => thematicMetaphorClick(e.target)} className={`DeepMetaphor`} 
            style={active ? activeStyle : (hover ? defautHoverStyle : defautStyle) }>
                {label}
            </div> 
        } else {
            return <div></div>
        }
    }

    if (typeof unselected != 'undefined') {

        const defautStyle = {
            borderColor: '#fff', 
            color: color, 
            backgroundColor: '#fff'
        }

        const activeStyle = {
            borderColor: color, 
            color: color, 
            backgroundColor: ConvertHexToRGBA(`${color}`, 10)
        }

        return <div style={active ? activeStyle : defautStyle}></div> 
    }

    return <div onClick={metaphorClick} className={`DeepMetaphor metaphor-${data ? data.key : ""} ${active ? 'active' : ''}`}>
        {data ? data.label : ""}
    </div> 
}

export default Metaphor
