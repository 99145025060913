import './MetaphorClassificationContainer.scss';
import { Layout } from 'antd';
import React from 'react';
import SimileHeader from '../../components/Header/SimileHeader';
import { withRouter } from 'react-router';

const MetaphorClassificationContainer: React.FC = () => {
	const { Content } = Layout;

	return (
		<Layout className="layout" style={{ paddingTop: '64px' }}>
			<SimileHeader />
			<Content className="container-metaphor-classification">
				<iframe src={'https://metaphor-classification-oxub.onrender.com/'} width={'100%'} height={'100%'} />
			</Content>
		</Layout>
	);
};

export default withRouter(MetaphorClassificationContainer);
