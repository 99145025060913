import React, { useState, useEffect } from "react"
import { Row, Col, Typography, Space } from "antd"
import Moment from 'react-moment'
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useHistory } from "react-router-dom";
import { StatusType } from '../../data/Status'
import { RatingsType } from '../../data/Rating'
import { CommentOutlined } from '@ant-design/icons';
import { Link, animateScroll } from "react-scroll"

const HeaderCollapseResponseIndex = ({index, project}) => {
  const history = useHistory()
  const { Text } = Typography

  const {
    target,
    edited_by, 
    edited_date,
    survey,
    savedTotalResponses,
    totalComments,
    totalDiscard,
    totalCompleted
  } = project;


  const goToComments = (e) => {
    e.stopPropagation()
    if (window.location.pathname == "/") {
      history.push(`/project/${survey}`)
    } else {
      scrollToBottom()
    }   
  }

  const scrollToBottom = ()  =>{
    animateScroll.scrollToBottom({
      containerId: "ProyectComment"
    })
  }

  return (
    <div>
      <Row>
        <Col span={3}>
          <Text type="secondary" className="subtitle">
            Responses
          </Text>
          <div>
            <Text>{savedTotalResponses}</Text>
          </div>
        </Col>
        <Col span={3}>
          <Text type="secondary" className="subtitle">
            Target
          </Text>
          <div>
            <Text>{target}</Text>
          </div>
        </Col>
        <Col span={3}>
          <Text type="secondary" className="subtitle">
            Completed
          </Text>
          <div>
            <Text>{totalCompleted}</Text>
          </div>
        </Col>
        <Col span={3}>
          <Text type="secondary" className="subtitle">
            Discarded
          </Text>
          <div>
            <Text>{totalDiscard}</Text>
          </div>
        </Col>
        <Col span={5} offset={3}>
          <Text type="secondary" className="subtitle">
            Last Edit
          </Text>
          <div>
            <Text strong><Moment format="YYYY/MM/DD">{edited_date && edited_date._seconds * 1000}</Moment></Text>
            <Text type="secondary"> by </Text>
            <Text strong>{edited_by}</Text>
          </div>
        </Col>
        <Col span={3}>
          <Space>
            <CommentOutlined style={{color: '#979797'}} />
            {index ?
              <Text>{totalComments} comments</Text>                      
              :
              <Link to="ProyectComment" offset={-100} spy={true} smooth={true}>
                <Text style={{ color: "#2074C1"}}>{totalComments} comments</Text>
              </Link>  
            }
          </Space>
				</Col>
      </Row>
    </div>
  );
};

export default HeaderCollapseResponseIndex;
