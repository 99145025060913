import './ProjectIndex.scss';
import { Button, Col, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import ProjectIndexList from '../../components/ProjectIndexList/ProjectIndexList';
import React from 'react';
import SearchProject from '../../components/SearchProject/SearchProject';
import SimileHeader from '../../components/Header/SimileHeader';
import { useProjectIndex } from './useProjectIndex';
import { withRouter } from 'react-router';
import Loading from '../../components/Loading/Loading';

const ProjectIndex: React.FC = () => {
	const { Content } = Layout;
	const { Title } = Typography;
	const {
		pagination,
		loading,
		analysts,
		projects,
		filteredProjects,
		onSearch,
		setTotalPages,
		setCurrentPage
	} = useProjectIndex();

	if (loading) {
		return <Loading />;
	}

	return (
		<Layout className="layout" style={{ paddingTop: '64px'}}>
			<SimileHeader />
			<Content className="container" >
				<Row align="middle" justify="space-between" style={{marginTop:'28px'}} >
					<Col>
						<Title style={{ marginBottom: '0px'}}>Projects</Title>
					</Col>
					<Col>
						<Link to="/create-project">
							<Button type="primary">+ Create Project</Button>
						</Link>
					</Col>
				</Row>
				<SearchProject onSearch={onSearch} projects={projects} />
				<ProjectIndexList setCurrentPage={setCurrentPage} setTotalPages={setTotalPages} pagination={pagination} analysts={analysts} projects={filteredProjects.length > 0 ? filteredProjects : projects}/>
			</Content>
		</Layout>
	);
};

export default withRouter(ProjectIndex);
