import React, { useState, useEffect } from "react"
import {Typography, Space, Row, Col, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DeepMetaphors from "./DeepMetaphors"
import ThematicMetaphors from "./ThematicMetaphors"

import { Fade } from 'react-reveal'

const { Text } = Typography

const Metaphors = ({addAdditionalMetaphor, onMetaphorClick, onThematicMetaphorClick, response}) => {

    const [isThematicVisible, setIsThematicVisible] = useState(false)
    const [currentColor, setCurrentColor] = useState('#424B5B')
    const [thematicMetaphores, setThematicMetaphores] = useState([])

    const deepClick = (metaphor) => {
        setThematicMetaphores(metaphor.thematic)
        setCurrentColor(metaphor.color)

        if (metaphor.thematic.length > 0) {
            setIsThematicVisible(true)
        } else {
            setIsThematicVisible(false)
        }
        onMetaphorClick(metaphor)
    }
    
    useEffect(() => {
        if (typeof response.simile_metaphor != 'undefined') {
            setCurrentColor(response.simile_metaphor.color)
            setThematicMetaphores(response.simile_metaphor.thematic)
            if (response.simile_metaphor.thematic && response.simile_metaphor.thematic.length > 0) {
                setIsThematicVisible(true)
            } else {
                setIsThematicVisible(false)
            }
        }
    }, [response.simile_metaphor])
        
    return (
        <div className="Metaphors" style={{ marginTop: '-5px'}}>
            <Row justify="space-between">
                <Col style={{ marginTop: '10px'}}>
                    <Text className="subtitle-large">Primary Metaphors</Text>
                </Col>
                <Col>
                    <Button onClick={addAdditionalMetaphor} size="small">
                        <PlusOutlined /> Add additional metaphor
                    </Button>
                </Col>                    
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: '17px'}}>
                    <Space direction="vertical" style={{ marginBottom: '14px'}}>
                        <Text className="subtitle">DEEP METAPHOR</Text>
                        <DeepMetaphors current={response.simile_metaphor ? response.simile_metaphor.key : ''} response={response} onMetaphorClick={deepClick} />
                    </Space>                                                                       
                </Col>
            </Row>  
            <Fade when={isThematicVisible}>
                <Row>
                    <Col span={24} style={{ marginTop: '5px' }}>
                        <Space direction="vertical" style={{ marginBottom: '6px'}}>
                            <Text className="subtitle">THEMATIC METAPHOR</Text>
                            <ThematicMetaphors response={response} onThematicMetaphorClick={onThematicMetaphorClick} color={currentColor} thematics={thematicMetaphores} />
                        </Space>                                                                       
                    </Col>
                </Row>  
            </Fade>
                       
        </div>
    )
}

export default Metaphors
