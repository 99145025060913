import { useFirestore, useFirestoreCollectionData, useFirestoreDocData} from 'reactfire';
import { animateScroll } from 'react-scroll';
import { Comment } from '../../interfaces/Comment';
import { Mixpanel } from '../../tools/MixPanel';
import { Project } from '../../interfaces/Project';
import React from 'react';
import { Response } from '../../interfaces/Response';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

interface UseResponseDetailStore {
	response: Response;
	tooglePreview: () => void;
	project: Project;
	comments: Comment[];
	allResponses: Response[];
	commentsQuery: any;
	isPreviewShow: boolean;
}

export const useResponseDetail = (): UseResponseDetailStore => {
	const [ isPreviewShow, setIsPreviewShow ] = React.useState(false);

	const firestore = useFirestore();
	const { id }: {id: string} = useParams();
	const query = firestore.collection('responses').doc(id);
	const response: Response = useFirestoreDocData(query, {idField: 'idFirebase'});
	const projectQuery = firestore.collection('projects').doc(response.surveyId);
	const project: Project = useFirestoreDocData(projectQuery);
	const commentsQuery: any = firestore.collection('responses').doc(id).collection('comments');
	const comments: Comment[] = useFirestoreCollectionData(commentsQuery, {idField: 'idFirebase'});
	const allResponsesQuery = firestore.collection('responses').where('surveyId', '==', response.surveyId);
	const allResponses = useFirestoreCollectionData(allResponsesQuery, {idField: 'idFirebase'});
	const location: any = useLocation();

	const scrollToBottom = (): void =>{
		setTimeout(() => {
			animateScroll.scrollToBottom({
				duration: 1000,
				smooth: true,
			});
		}, 500);
	};

	const tooglePreview = (): void => {
		setIsPreviewShow(!isPreviewShow);
	};

	React.useEffect(() => {
		if (location.showComments) {
			scrollToBottom();
		}

		Mixpanel.track('view', {
			id: 'response-detail',
			responseId: id,
		});
	}, [id, location]);

	return {
		response,
		tooglePreview,
		project,
		comments,
		allResponses,
		commentsQuery,
		isPreviewShow,
	};
};
