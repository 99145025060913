import React from "react";
import { Typography, Row, Col, Switch } from "antd";
import "./ResponseIndexSort.scss";
import IconSelect from "../Select/IconSelect";

const ResponseIndexSort = (props) => {
  const { Title, Text } = Typography;
  const { title, onChange, onSort, showFilter, sort } = props;
  const options = [   
      {value: "Status"},
      {value: "Deep Metaphor"},
      {value: "Thematic Metaphor"},
      {value: "Sentiment"},
      {value: "Rating"},  
      {value: "Last edited by"},  
      {value: "Starred"}
  ];

  const defaultSelected = {
    value: "Response number",
    key: "number"
  }

  return (
    <div className="ResponseIndexSort">
      <Row align="middle" justify="space-between">
        <Col span={12}>
          <Title level={3} style={{marginBottom: '0px'}}>{title}</Title>
        </Col>
        <Col span={12}>
          <Row align="middle">
            <Col align="right" span={5} offset={4}>
              <Text strong>Sort by</Text>
            </Col>
            <Col span={7} offset={1}>
              <IconSelect value={sort} handleChange={onSort} options={options} defaultSelected={defaultSelected}/>
            </Col>
            <Col span={6} offset={1} flex="1">
              <Row gutter={[16, 0]} justify="end">
                <Col>
                  <Text strong>Filter</Text>
                </Col>
                <Col>
                  <Switch checked={showFilter} defaultChecked={showFilter} onChange={onChange} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ResponseIndexSort;
