import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { useAuth } from 'reactfire';

interface UseRecoverStore {
	loadingButton: boolean;
	error: string;
	success: string;
	onFinish: (value: {email: string}) => Promise<void>;
}

export const useRecover = (): UseRecoverStore => {
	const [loadingButton, setLoadingButton] = React.useState(false);
	const [error, setError] = React.useState('');
	const [success, setSuccess] = React.useState('');
	const auth = useAuth();

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'recover-password',
		});
	}, []);

	const onFinish = async (values: any): Promise<void> => {
		setLoadingButton(true);
		const {email} = values;

		auth.sendPasswordResetEmail(email).then( () => {
			setSuccess('An email has been sent to recover your password');
			setLoadingButton(false);
		}).catch( error => {
			const { message } = error;

			setError(message);
			Mixpanel.track('error', {
				type: 'recover',
				message: message,
			});
			setLoadingButton(false);
		});
	};

	return {
		loadingButton,
		error,
		success,
		onFinish,
	};
};
