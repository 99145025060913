import 'antd/dist/antd.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';
import axios from 'axios';
import { configDev } from './firebaseConfig';
import { FirebaseAppProvider } from 'reactfire';
import Loading from './components/Loading/Loading';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

WebFont.load({
	google: {
		families: ['Source Sans Pro:200,300,400,500,600,700,900', 'sans-serif'],
	},
});

const AppWithFirebase: React.FC = () => {
	const [firebaseConfig, setFirebaseConfig] = React.useState<{}>();

	React.useEffect(() => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			setFirebaseConfig(configDev);
		} else {
			axios('/__/firebase/init.json')
				.then(result => {
					const firebaseConfig = result.data;

					setFirebaseConfig(firebaseConfig);
				});
		}
	}, []);

	if (!firebaseConfig) { return <Loading />; }

	return (
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<React.Suspense fallback={<Loading />}>
				<App/>
			</React.Suspense>
		</FirebaseAppProvider>
	);
};

ReactDOM.render(
	<AppWithFirebase />,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
