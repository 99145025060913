import mixpanel from 'mixpanel-browser';
mixpanel.init('a763e2887688bdcc4a1b5076bead15cc');

const envCheck = true; //process.env.NODE_ENV === 'production';

const actions = {
	identify: (id: string): void => {
		if (envCheck) {mixpanel.identify(id);}
	},
	alias: (id: string): void => {
		if (envCheck) {mixpanel.alias(id);}
	},
	track: (name: string, props: {}): void => {
		if (envCheck) {mixpanel.track(name, props);}
	},
	people: {
		set: (props: {}): void => {
			if (envCheck) {mixpanel.people.set(props);}
		},
	},
};

export const Mixpanel = actions;
