export const MetaphorsData = [
	{
		label: 'Balance',
		value: 'Balance',
		key: 'balance',
		color: '#13A8A8',
		thematic: [
			'physical balance',
			'physical imbalance',
			'emotional balance',
			'emotional imbalance',
			'social & moral balance',
			'social & moral imbalance',
		],
	},
	{
		label: 'Connection',
		value: 'Connection',
		key: 'connection',
		color: '#2F54EB',
		thematic: [
			'connection to self',
			'disconnection to self',
			'connection to others',
			'disconnection to others',
			'ambiguous connection',
			'ambiguous disconnection',
		],
	},
	{
		label: 'Container',
		value: 'Container',
		key: 'container',
		color: '#3696D6',
		thematic: [
			'open container',
			'exposed container',
			'closed container',
			'protected container',
			'ambiguous container',
		],
	},
	{
		label: 'Control',
		value: 'Control',
		key: 'control',
		color: '#E07100',
		thematic: [
			'mental control',
			'mental out of control',
			'bodily in control',
			'bodily out of control',
			'general in control',
			'general out of control',
		],
	},
	{
		label: 'Force',
		value: 'Force',
		key: 'force',
		color: '#E73F2B',
		thematic: [
			'physical force',
			'war force',
			'environmental force',
		],
	},
	{
		label: 'Ideal',
		value: 'Ideal',
		key: 'ideal',
		color: '#8852C1',
		thematic: [
			'pristine, perfect',
			'polluted, imperfect',
		],
	},
	{
		label: 'Journey',
		value: 'Journey',
		key: 'journey',
		color: '#D60E66',
		thematic: [
			'forward journey',
			'backward journey',
			'fast journey',
			'slow journey',
			'known journey',
			'unknown journey',
			'smooth journey',
			'obstacle journey',
		],
	},
	{
		label: 'Nature',
		value: 'Nature',
		key: 'nature',
		color: '#3A7C49',
		thematic: [
			'landscape',
			'animals',
			'nature elements',
		],
	},
	{
		label: 'System',
		value: 'System',
		key: 'system',
		color: '#3D5172',
		thematic: [
			'mechanical system',
			'ritual system',
			'cause/effect system',
		],
	},
	{
		label: 'Transformation',
		value: 'Transformation',
		key: 'transformation',
		color: '#965353',
		thematic: [
			'physical positive transformation',
			'mental positive transformation',
			'physical negative transformation',
			'mental negative transformation',
			'no transformation',
		],
	},
];
