import React, { Fragment, useState, useEffect } from 'react'
import { List, Space, Modal, Button, Table, Typography, Row, Col, message, Input, Divider, Anchor} from 'antd'
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import "./ImageBankDetailTable.scss"
import Metaphor from '../Metaphors/Metaphor';
import ImageBankFilters from '../ImageBankFilters/ImageBankFilters';
import { useFirestore } from 'reactfire';
import MetaphorsImageBank from '../Metaphors/MetaphorsImageBank';

const { Text, Link } = Typography

const ImageBankDetailTable = ({name, id, bank, allImages, setLoading}) => {

  const cleanMetaData = {
    lastModified: "",
    name: "",
    size: "",
    type: "",
    url: ""
  }
  const [currentImage, setCurrentImage] = useState([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [gridVisible, setGridVisible] = useState(true)
  const [imageMetaData, setImageMetaData] = useState(cleanMetaData)
  const firestore = useFirestore();
  const [currentBank, setCurrentBank] = useState(bank)
  const [selectedImages, setSelectedImages] = useState([])

  const handleOk = () => {
    setVisibleModal(false)
  }

  const handleCancel = () => {
    setVisibleModal(false)
  }

  const handleEditImage = (item)  => {
    setCurrentImage(item)
    setImageMetaData(item)
    setVisibleModal(true)
  }

  const showListGrid = (type) => {
    if (type === "grid") {
      setGridVisible(true)
    } else {
      setGridVisible(false)
    }
  }

  const deleteImage = (image) => {
    setCurrentImage(image)
    setVisibleDeleteModal(true)
  }

  const copyToClipboard = (image) => {
    navigator.clipboard.writeText(image.url);
    message.success('Your link was copied to the clipboard');        
}  

  const columns = [
    {
      title: 'File',
      render: (image) => renderImage(image),
      align: 'left',
      width: '156px'
    },
    {
      title: 'Name',
      render: (image) => renderName(image),
      align: 'left'
    },
    {
      title: '',
      render: (image) => {
        return (
          <>
            <Button type="link" onClick={ () => copyToClipboard(image) } icon={<CopyOutlined />}>Copy URL</Button>
            <Button type="link" onClick={() => deleteImage(image) }  danger icon={<DeleteOutlined />}>Delete image</Button>
          </>
        )
      },
      align: 'right'
    }
  ];

  const renderImage = (image) => {
    return (
      <Fragment>
        <div onClick={ () => handleEditImage(image) } className="image-container"><img src={image.url} alt="" /></div>
      </Fragment>
    )
  }

  const renderName = (item) => {
    return (
      <Fragment>
        <div onClick={() => handleEditImage(item)}>
          <Text>{item.name}</Text>        
        </div>
        <div style={{marginBottom: '5px'}}>
        <Metaphor active={true} data={item.metaphor ? item.metaphor : {
                          label: "N/A",
                          value: "N/A",
                          key: "na",
                          color: "#c1c1c1",
                          thematic: [
                            "N/A"
                          ]
                        }} />
        </div>
        <div>
        <Metaphor active={true} label={item.thematicmetaphor ? item.thematicmetaphor : 'N/A'} color={item.metaphor ? item.metaphor.color : '#c1c1c1'} />
        </div>
      </Fragment>
    )
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedImages(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const handleOkDelete = () => {
    firestore.collection('images')
    .doc(id)
    .collection('bank')
    .doc(currentImage.key) 
    .delete()
    .then(function() {
       message.success(`Image ${currentImage.name} deleted`);
    }).catch(function(error) {
        console.error("Error removing document: ", error);
        message.error(error)
    });  
  }

  const handleCancelDelete = () => {
    setVisibleDeleteModal(false)
  }

  const onMetaphorClick = (metaphor) => {
    firestore.collection('images')
    .doc(id)
    .collection('bank')
    .doc(currentImage.key) 
    .update({ metaphor: metaphor})
    .then(function() {
       message.success(`Metaphor applied`);
    }).catch(function(error) {
        console.error("Error removing document: ", error);
        message.error(error)
    });     
  }

  const onThematicMetaphorClick = (thematicmetaphor) => {
    firestore.collection('images')
    .doc(id)
    .collection('bank')
    .doc(currentImage.key) 
    .update({ thematicmetaphor: thematicmetaphor})
    .then(function() {
       message.success(`Thematic Metaphor applied`);
    }).catch(function(error) {
        console.error("Error removing document: ", error);
        message.error(error)
    }); 
  }

  const onFilter = (bankFiltered) => {
    setCurrentBank(bankFiltered)
  }


  useEffect(() => {
    setCurrentBank(bank)
  }, [bank])

  return (
    <div className="box-content" style={{marginBottom:'10px', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '26px', paddingRight: '26px'}} >
      <ImageBankFilters setLoading={setLoading} selectedImages={selectedImages} allImages={allImages} bank={bank} onFilter={onFilter} id={id} name={name} showListGrid={showListGrid}  />
      {gridVisible &&
        <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={currentBank}
            className="list-grid-images"
            renderItem={item => (
              <List.Item>
                <div onClick={ () => handleEditImage(item) } className="image-container big">
                  <img src={item.url} alt="" />
                </div>              
                <Row>
                  <Col>
                      <div style={{marginTop:'10px', marginBottom: '5px'}}>
                        <Metaphor active={true} data={item.metaphor ? item.metaphor : {
                          label: "N/A",
                          value: "N/A",
                          key: "na",
                          color: "#c1c1c1",
                          thematic: [
                            "N/A"
                          ]
                        }} />
                      </div>
                      <div>
                        <Metaphor active={true} label={item.thematicmetaphor ? item.thematicmetaphor : 'N/A'} color={item.metaphor ? item.metaphor.color : '#c1c1c1'} />
                      </div> 
                  </Col>
                </Row >
              </List.Item>
            )}
          />               
      }
      {!gridVisible &&
              <Table
              className="ImageBankDetailTable"
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={currentBank}
            /> 
      }
        <Modal
        title="Image Details"
        visible={visibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Save changes"
        width={1091}
        className="modal-preview-bank"
        >
          <Row>
            <Col span={14} style={{ padding: "24px"}}>
              <img src={imageMetaData.url} className="image-preview-bank" alt="" />
            </Col>
            <Col span={10} style={{ padding: "24px", backgroundColor: "#ECECEB"}}>
              <Space style={{ width: '100%' }} direction="vertical">
                  <Text>
                    <strong>File name:</strong><br/>
                    {imageMetaData.name}
                  </Text>
                  <Text>
                    <strong>File type: </strong><br/>
                    {imageMetaData.type}
                  </Text>    
                  <Text>
                    <strong>File size: </strong><br/>
                    {imageMetaData.size}
                  </Text>   
                  <Text>
                    <strong>File URL: </strong><br/>                    
                  </Text>
                  <Row>
                    <Col style={{ overflow: 'hidden' }} span={18}>
                      <Text style={{ width: '100%', overflow: 'hidden' }}>
                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imageMetaData.url}</span>
                      </Text>                      
                    </Col>
                    <Col offset={1} span={5}>
                        <Link onClick={ () => copyToClipboard(currentImage) }> <CopyOutlined /> Copy URL</Link>                      
                    </Col>
                  </Row>                 
                  <Text>
                    <strong>File source: </strong><br/>
                    <Input placeholder="File source"/>
                  </Text>                                                                                                 
                </Space>            
            </Col>
          </Row>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <Row>
            <Col style={{ padding: '24px'}}>
              <MetaphorsImageBank image={currentImage} onThematicMetaphorClick={onThematicMetaphorClick} onMetaphorClick={onMetaphorClick} />              
            </Col>
          </Row>
          <Row>
          </Row>
        </Modal>
        <Modal
        title="DELETE IMAGE"
        visible={visibleDeleteModal}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        cancelText="No, cancel"
        okText="Yes, delete"
        >
          <Row>
            <Text>
               Are you sure you want to delete selected images?
            </Text>
          </Row>
        </Modal>        
    </div> 
  );

}

export default ImageBankDetailTable
