import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AccountSetup from './containers/AccountSetup/AccountSetup';
import CreateImage from './containers/CreateImage/CreateImage';
import CreateProject from './containers/CreateProject/CreateProject';
import EditImage from './containers/EditImage/EditImage';
import EditProject from './containers/EditProject/EditProject';
import Error from './containers/Error/Error';
import ImageBankDetail from './containers/ImageBankDetail/ImageBankDetail';
import ImageBanksList from './containers/ImageBanksList/ImageBanksList';
import Login from './containers/Login/Login';
import MetaphorClassificationContainer from './containers/MetaphorClassificationContainer/MetaphorClassificationContainer';
import ProjectIndex from './containers/ProjectIndex/ProjectIndex';
import React from 'react';
import Recover from './containers/Recover/Recover';
import Redirect from './containers/Redirect/Redirect';
import ResponseDetail from './containers/ResponseDetail/ResponseDetail';
import { ResponseFilterProvider } from './providers/ResponseFilterProvider';
import ResponseIndex from './containers/ResponseIndex/ResponseIndex';
import { UserProvider } from './providers/UserProvider';

const App: React.FC = () => (
	<Router>
		<UserProvider>
			{/* <ScrollToTop>       */}
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/recover">
					<Recover />
				</Route>
				<Route exact path="/">
					<ProjectIndex />
				</Route>
				<Route exact path="/create-project">
					<CreateProject />
				</Route>
				<Route exact path="/edit-project/:id">
					<EditProject />
				</Route>
				<Route exact path="/account-setup">
					<AccountSetup />
				</Route>
				<Route exact path="/image-bank/create">
					<CreateImage />
				</Route>
				<Route exact path="/image-bank">
					<ImageBanksList />
				</Route>
				<Route exact path="/image-bank/edit/:id">
					<EditImage />
				</Route>
				<Route exact path="/image-bank/:id">
					<ImageBankDetail />
				</Route>
				<Route exact path="/metaphor-classification">
					<MetaphorClassificationContainer />
				</Route>
				<Route exact path="/redirect/:project/:page">
					<Redirect />
				</Route>
				<ResponseFilterProvider>
					<Switch>
						<Route path="/project/:id">
							<ResponseIndex />
						</Route>
						<Route exact path="/response/:id">
							<ResponseDetail />
						</Route>
					</Switch>
				</ResponseFilterProvider>
				<Route path="*">
					<Error />
				</Route>
			</Switch>
			{/* </ScrollToTop> */}
		</UserProvider>
	</Router>
);

export default App;
