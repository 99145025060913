import React, { useState, useEffect } from 'react'
import { Space } from 'antd'
import Metaphor from "./Metaphor"
import './DeepMetaphors.scss'

const DeepMetaphors = ({thematics, color, onThematicMetaphorClick, response, additional, image}) => {

    const [current, setCurrent] = useState()
    
    const onMetaphorClick = (metaphor) => {
        const parser = new DOMParser
        const dom = parser.parseFromString(
            '<!doctype html><body>' + metaphor,
            'text/html');
        const decodedString = dom.body.textContent
        setCurrent(decodedString)

        if (typeof additional != 'undefined') {
            const metaphor = {
                value: decodedString,
                additional: additional
            }
            onThematicMetaphorClick(metaphor)
        } else {
            onThematicMetaphorClick(decodedString)          
        }

    }

    useEffect(() => {
        if (typeof response != 'undefined' && typeof response.simile_thematicpmetaphor != 'undefined') {
            setCurrent(response.simile_thematicpmetaphor)
        }

        if (typeof image != 'undefined' && typeof image.metaphor != 'undefined') {
            setCurrent(image.thematicmetaphor)
        }        
    }, [thematics,])

    useEffect(() => {
        console.log("cambiaron la adicional")
        console.log(additional)
        if (typeof additional != 'undefined' && typeof additional.selected_thematic != 'undefined') {
            setCurrent(additional.selected_thematic)
        }
    }, [additional])
    
    return (
        <Space>
            {thematics && thematics.map(metaphor => {
                return <Metaphor active={metaphor === current ? true : false} onMetaphorClick={onMetaphorClick} color={color} label={metaphor} />
            })}
        </Space>
    )
}

export default DeepMetaphors
