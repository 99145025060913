import React, { useState, useEffect, Fragment, useContext } from "react"
import {Typography, Space, Row, Col, message, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DeepMetaphors from "./DeepMetaphors"
import ThematicMetaphors from "./ThematicMetaphors"
import { Fade } from 'react-reveal'
import { useFirestore } from "reactfire"
import { CloseOutlined } from '@ant-design/icons';
import './AdditionalMetaphors.scss'
import DeleteAdditional from './DeleteAdditional';

const { Text } = Typography

const AdditionalMetaphors = ({additionals, id}) => {

    const firestore = useFirestore()

    const openNotification = type => {
        message.success(`${type} Saved`)
    };   

    const deepClick = (metaphor) => {
        const idAdditional = metaphor.additional
        delete metaphor.additional
        firestore.collection('responses')
        .doc(id)
        .collection('additionals_metaphors')
        .doc(idAdditional)
        .update(metaphor)
        .then(() => {
            console.log("Document written with ID: ", idAdditional)
            clearThemathicAdditional(id, idAdditional)
            openNotification("Additional deep metaphor")
        })
        .catch((error) => {
            console.error("Error updating document: ", error)
        })  
    }

    const clearThemathicAdditional = (id, idFirebase) => {
        firestore.collection('responses')
        .doc(id)
        .collection('additionals_metaphors')
        .doc(idFirebase)
        .update({selected_thematic: ""})
        .then(() => {
            console.log("Document written with ID: ", idFirebase)
        })
        .catch((error) => {
            console.error("Error updating document: ", error)
        })           
    }

    const themathicClick = (metaphor) => {
        firestore.collection('responses')
        .doc(id)
        .collection('additionals_metaphors')
        .doc(metaphor.additional.idFirebase)
        .update({selected_thematic: metaphor.value})
        .then(() => {
            console.log("Document written with ID: ", metaphor.additional.idFirebase)
            openNotification("Additional thematic metaphor")
        })
        .catch((error) => {
            console.error("Error updating document: ", error)
        })          
    }

    useEffect(() => {

    }, [])
        
    return (
        <div className="Metaphors" style={{ marginTop: '-5px'}}>
            <Row justify="space-between">
                <Col style={{ marginTop: '10px'}}>
                    <Text className="subtitle-large">Additional Metaphors</Text>
                </Col>                   
            </Row>
            <div style={{marginLeft: '40px', marginTop: '17px'}}>
                {additionals.map(additional => (
                    <div className="aditional-container">
                        <Row>
                            <Col span={24}>
                                <Space direction="vertical" style={{ marginBottom: '14px'}}>
                                    <Text className="subtitle">DEEP METAPHOR</Text>
                                    <DeepMetaphors response={additional} current={additional.key} onMetaphorClick={deepClick} additional/>
                                </Space>                                                                       
                            </Col>
                        </Row>  
                        <DeleteAdditional id={id} additional={additional} />
                        <Fade collapse when={additional.key != 'na'}>
                            <Row>
                                <Col span={24} style={{ marginTop: '5px'}}>
                                    <Space direction="vertical">
                                        <Text className="subtitle">THEMATIC METAPHOR</Text>
                                        <ThematicMetaphors onThematicMetaphorClick={themathicClick} additional={additional} color={additional.color} thematics={additional.thematic} />
                                    </Space>                                                                       
                                </Col>
                            </Row>  
                        </Fade>
                        <Divider/>                        
                    </div>
                ))}
            </div>                       
        </div>
    )
}

export default AdditionalMetaphors
