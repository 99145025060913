import * as firebase from "firebase/app"
import "firebase/auth"
import * as axios from "axios"


// const remoteConfig = firebase.remoteConfig();
// remoteConfig.defaultConfig = {
//     apiKey: "AIzaSyC1-K1x8nO22mPz_dtFSXnjVemrUBsglNg",
//     authDomain: "simile-analyst-tool.firebaseapp.com",
//     databaseURL: "https://simile-analyst-tool.firebaseio.com",
//     projectId: "simile-analyst-tool",
//     storageBucket: "simile-analyst-tool.appspot.com",
//     messagingSenderId: "471693978658",
//     appId: "1:471693978658:web:bc89bcf3fd570e29006e7b",
//     measurementId: "G-RGWR5XBD85",
//     environment: "staging",
// }

// export const config = {
//     apiKey: remoteConfig.getValue("apiKey"),
//     authDomain: remoteConfig.getValue("authDomain"),
//     databaseURL: remoteConfig.getValue("databaseURL"),
//     projectId: remoteConfig.getValue("projectId"),
//     storageBucket: remoteConfig.getValue("storageBucket"),
//     messagingSenderId: remoteConfig.getValue("messagingSenderId"),
//     appId: remoteConfig.getValue("appId"),
//     measurementId: remoteConfig.getValue("measurementId"),
//     environment: remoteConfig.getValue("environment"),
// }

// console.log("================== FIREBASE CONFIG ====================")
// console.log(remoteConfig)
// console.log(remoteConfig.getValue("authDomain"))


export const config = {
    apiKey: "AIzaSyAam3lr8f_sl3_6fv6zzmQKHSh355jRI1s",
    authDomain: "simile-analyst-tool-5aaae.firebaseapp.com",
    databaseURL: "https://simile-analyst-tool-5aaae.firebaseio.com",
    projectId: "simile-analyst-tool-5aaae",
    storageBucket: "simile-analyst-tool-5aaae.appspot.com",
    messagingSenderId: "487770002175",
    appId: "1:487770002175:web:ae474b3336870c0ad432ca",
    measurementId: "G-0WDNV48BLN"
};


export const configDev = {
    apiKey: "AIzaSyC1-K1x8nO22mPz_dtFSXnjVemrUBsglNg",
    authDomain: "simile-analyst-tool.firebaseapp.com",
    databaseURL: "https://simile-analyst-tool.firebaseio.com",
    projectId: "simile-analyst-tool",
    storageBucket: "simile-analyst-tool.appspot.com",
    messagingSenderId: "471693978658",
    appId: "1:471693978658:web:bc89bcf3fd570e29006e7b",
    measurementId: "G-RGWR5XBD85",
    environment: "staging",
};


const app = firebase.initializeApp(config, "production")

export default app
