import React, { useEffect, useState } from 'react'
import { message, Upload, Row, Col, Typography, Input, Button, Modal, Select, Space } from 'antd'
import IconSelect from '../Select/IconSelect'
import { useFirestore, useFirestoreCollectionData, useFirebaseApp, StorageImage, useStorage, useStorageDownloadURL } from 'reactfire'
import { MetaphorsData } from '../../data/MetaphorsData'
import { Rating } from '../../data/Rating'
import _ from 'lodash'
import { SearchOutlined, UnorderedListOutlined, AppstoreOutlined, UploadOutlined } from "@ant-design/icons";
import "./ImageBankFilters.scss";

const ImageBankFilters = ({id, name, showListGrid, bank, onFilter, allImages, selectedImages, setLoading}) => {
	const {Text} = Typography;
	const { Option } = Select;

	const all = {
		value: "All",
		key: "all"
	}

    const bulk = {
		value: "Bulk actions",
		key: "bulk"
	}

    const BulkActionsTypes = {
		assignDeep: 'Assign metaphor',
		copyTo: 'Copy to'
	}    
		  
    const statusImageBank = [
		  {
			value: BulkActionsTypes.assignDeep
		  },
		  {
			value: BulkActionsTypes.copyTo
		  }		  	  
	] 

	const firestore = useFirestore()
	const storage = useStorage()
	const [uploadVisible, setUploadVisible] = useState();
	const [copyVisible, setCopyVisible] = useState(false);
	const [metaphorsVisible, setMetaphorsVisible] = useState(false);	
	const [currenThematicMpetaphorModal, setCurrentThematicMetaphorModal] = useState(all.value)		
	const [thematicMpetaphor, setThematicMetaphor] = useState([all.value])	
	const [thematicMpetaphorModal, setThematicMetaphorModal] = useState([all.value])		
	const [currentThemathics, setCurrentThematics] = useState(all.value)
	const [currentThemathicsModal, setCurrentThematicsModal] = useState(all.value)	
	const [currentMetaphor, setCurrentMetaphor] = useState(all.value)	
	const [currentSearch, setCurrentSearch] = useState("");
	const [currentBulkAction, setCurrentBulkAction] = useState("")
	const [imageBankSelected, setImageBankSelected] = useState("")

	useEffect(() => {
		applyFilter()
	}, [currentMetaphor, currentThemathics, currentSearch]);
	
	const statusModalChangeMetaphors = (value) => {	
		const isAll = _.find(value, el => el === all.value)
		if (!((isAll && value.length > 2) || value.length === 0)) {


			let primary =  MetaphorsData.filter( (thematic) => {
				return thematic.label.toLowerCase() === value.toLowerCase()
			})
		
			let thematics = primary.map( (primary) => {
				return primary.thematic.map(thematic => {
					let value = capitalize(thematic)
					let item = {
						value: value
					}
					return item
				}) 
			})
			setCurrentThematicMetaphorModal(value)
			setThematicMetaphorModal(_.flatten(thematics))	
			return
		}
	}	

	const statusChangeMetaphors = (value) => {	
		const isAll = _.find(value, el => el === all.value)
		if (!((isAll && value.length > 2) || value.length === 0)) {


			let primary =  MetaphorsData.filter( (thematic) => {
				return thematic.label.toLowerCase() === value.toLowerCase()
			})
		
			let thematics = primary.map( (primary) => {
				return primary.thematic.map(thematic => {
					let value = capitalize(thematic)
					let item = {
						value: value
					}
					return item
				}) 
			})
			setCurrentMetaphor(value)
			setThematicMetaphor(_.flatten(thematics))	
			return
		}
	}		

	const statusChangeThemathicModal = (value) => {
		setCurrentThematicsModal(value)
	}

	const statusChangeThemathic = (value) => {
		setCurrentThematics(value)
	}

	const searchChange = (e) => {
		if (e.target.value.length > 3) {
			setCurrentSearch(e.target.value)
		} else {
			setCurrentSearch("")
		}
	}

	const capitalize = (str) => {
		let splitStr = str.toLowerCase().split(' ');
		for (let i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		return splitStr.join(' ');
	  }

    const applyFilter = () => {
		 let bankFiltered = bank
		
		if (currentMetaphor.length > 0) {
			if (currentMetaphor !== all.value) {
				bankFiltered = bankFiltered.filter((imageBank) => {
					if (typeof imageBank.metaphor != 'undefined') {
						if (imageBank.metaphor.label.toLowerCase() === currentMetaphor.toLowerCase()) {
							return imageBank
						}					
					}
				})
			}
		} 
		
		if (currentThemathics.length > 0) {
			if (currentThemathics !== all.value) {
				bankFiltered = bankFiltered.filter((imageBank) => {
					if (typeof imageBank.metaphor != 'undefined') {
						if (imageBank.thematicmetaphor.toLowerCase() === currentThemathics.toLowerCase()) {
							return imageBank
						}					
					}
				})
			}
		}		
	
		if (currentSearch.length > 3) {
			console.log(currentSearch)
			bankFiltered = bankFiltered.filter((imageBank) => {
				if (typeof imageBank.metaphor != 'undefined') {
					if (imageBank.name.toLowerCase().includes(currentSearch.toLowerCase())) {
						return imageBank
					}					
				}
			})			
		}

        onFilter(bankFiltered)
	}

	const handleShowGrid = () => {
		showListGrid("grid")
	}

	const handleShowList = () => {
		showListGrid("list")
	}

	const handleChange = (info) => {
		if (info.file.status === 'uploading') {
		 //this.setState({ loading: true });
		return;
		}
		if (info.file.status === 'done') {
		// getBase64(info.file.originFileObj, imageUrl => this.setState({
		// 	imageUrl,
		// 	loading: false
		// }));
		}
	};

	const handleShowUpload = () => {
		setUploadVisible(true)
	}

	const handleCancelUpload = () => {
		setUploadVisible(false)
	}

	const handleOkUpload = () => {
		setUploadVisible(false)
	}
	

	const beforeUpload = (file) => {
		const isImage = file.type.indexOf('image/') === 0;
		if (!isImage) {
			message.error('You can only upload image file!');
		}
		
		// You can remove this validation if you want
		const isLt5M = file.size / 1024 / 1024 < 50;
		if (!isLt5M) {
			message.error('Image must smaller than 50MB!');
		}
		return isImage && isLt5M;
	};

	const customUpload = async ({ onError, onSuccess, file }) => {
		console.log("file", file)
		const metadata = {
			contentType: 'image/jpeg'
		}
		const storageRef = await storage.ref();
		const imageName = file.name //generateHashName(); //a unique name for the image
		const imgFile = storageRef.child(`bank/${id}/${imageName}`);
		try {
			const image = await imgFile.put(file, metadata);
			let url = await imgFile.getDownloadURL()
			url = url.split("&token")
			url = url[0]

			firestore.collection('images')
			.doc(id)
			.collection('bank')
			.add({
				name: file.name,
				lastModified: file.lastModified,
				type: file.type,
				size: file.size,
				url: url
			})
			.then(() => {
				console.log("image uploaded ", image)				
				onSuccess(null, image);
			})
			.catch((error) => {
				console.error("Error updating document: ", error)
				onError(error);
			}) 
		} catch(e) {
			console.log(e)
			onError(e);
		}
	};	

	
	const handleOkCopy = () => {
		console.log(selectedImages)

		if (id === imageBankSelected) {
			message.warning('Please, you cannot copy images to the same image bank');
			return
		}

		if (imageBankSelected === "") {
			message.warning('Please, select the image bank to copy to');
			return
		}

		if (selectedImages.length == 0) {
			message.warning('Please, select at least 1 image using the list view');
			setCopyVisible(false)
			return
		}

		selectedImages.forEach(async image => {
			await firestore.collection('images')
			.doc(imageBankSelected)
			.collection('bank')
			.update(image)
			.catch((error) => {
				console.error("Error updating document: ", error)
			}) 
		});
	
		message.success('The images were copied correctly');
		setCopyVisible(false)
	}

	const handleCancelCopy = () => {
		setCopyVisible(false)
	}

	const handleBulkAction = () => {
		if (currentBulkAction === BulkActionsTypes.copyTo) {
			setCopyVisible(true)
		}

		if (currentBulkAction === BulkActionsTypes.assignDeep) {
			setMetaphorsVisible(true)
		}
	}

	const bulkActionChange = (value) => {
		setCurrentBulkAction(value)
	}

	const onChange = (value) => {
		setImageBankSelected(value)
	}

	const handleOkMetaphors = () => {

		console.log("current 1", currenThematicMpetaphorModal);
		console.log("current 2", currentThemathicsModal)
		let thematicSelected = currentThemathicsModal; 
		
		if (selectedImages.length == 0) {
			message.warning('Please, select at least 1 image using the list view');
			setMetaphorsVisible(false)
			return
		}
		
		if (currenThematicMpetaphorModal === all.value) {
			message.warning('Please the Deep Metaphor');
			return			
		}

		if (currentThemathicsModal === all.value) {
			message.warning('Please the Thematic Metaphor');
			return			
		}		

		let deepSelected = MetaphorsData.filter( (thematic) => {
			return thematic.label.toLowerCase() === currenThematicMpetaphorModal.toLowerCase()
		})

		selectedImages.forEach(async image => {
			await firestore.collection('images')
			.doc(id)
			.collection('bank')
			.doc(image.key)			
			.update({metaphor: deepSelected[0], thematicmetaphor: thematicSelected})
			.catch((error) => {
				console.error("Error updating document: ", error)
			}) 
		});
		
		message.success('The metaphors were applied correctly');
		setMetaphorsVisible(false)
	}

	const handleCancelMetaphors = () => {
		setMetaphorsVisible(false)
	}


	return (
		<div className="">          
			<Row className="image-bank-filter-header" gutter={[10, 0]} align="middle" style={{marginBottom: '5px'}}>
				<Col span={2}>
					<Text strong>View</Text>
				</Col>
				<Col span={4}>
					<Text strong>Deep Metaphor</Text>
				</Col>
				<Col span={4}>
					<Text strong>Thematic Metaphor</Text>
				</Col>
				<Col span={4}>
				</Col>                
			</Row>
			<Row gutter={[10, 0]} align="middle" style={{marginBottom: '15px'}}>
				<Col span={2}>
					<Row>
						<Col span={12}>
							<Button style={{ border: "none"}} onClick={handleShowGrid}>
								<AppstoreOutlined/>
							</Button> 							
						</Col>
						<Col span={12}>
							<Button style={{ border: "none"}} onClick={handleShowList}>
								<UnorderedListOutlined />
							</Button>  							
						</Col>
					</Row> 					                  
				</Col>
				<Col span={4}>
					<IconSelect value={currentMetaphor} handleChange={statusChangeMetaphors} options={MetaphorsData} defaultSelected={all} />
				</Col>
				<Col span={4}>
					<IconSelect value={currentThemathics} handleChange={statusChangeThemathic}  options={thematicMpetaphor} defaultSelected={all}/>
				</Col>
				<Col span={4}>
					<Input 
                          placeholder="Search"
						  onChange={searchChange}
                          suffix={<SearchOutlined />}
                    />
				</Col>  
				<Col span={4} align="right">
                    <IconSelect handleChange={bulkActionChange}  options={statusImageBank} defaultSelected={bulk}/>
				</Col>   
				<Col span={2} align="right">
                    <Button onClick={handleBulkAction} type="secondary">
                        Apply
                    </Button>  
				</Col>   
				<Col span={2}>
                        <Button onClick={handleShowUpload} type="primary">
                            <UploadOutlined /> Upload images
                        </Button>  
				</Col>                                                                
			</Row>    

			
			<Modal
				className="clean-modal"
				title={`Upload images to ${name}`}
				visible={uploadVisible}
				onOk={handleOkUpload}
				onCancel={handleCancelUpload}
				okText="Ok"
				cancelText="Cancel"          
				width={1006}
				>
					<Row style={{marginBottom: "20px" }}>
						<Col>
							<Upload
								multiple={true}
								name="image-bank"
								listType="picture-card"
								className="avatar-uploader"
								beforeUpload={beforeUpload}
								onChange={handleChange}
								customRequest={customUpload}					
								>						
								<Text style={{fontSize: "16px"}}>   
									Select Images
								</Text>  
							</Upload>							              
						</Col>
					</Row>
			</Modal>

			<Modal
				className="clean-modal modal-small-title"
				title={`COPY IMAGES`}
				visible={copyVisible}
				onOk={handleOkCopy}
				onCancel={handleCancelCopy}
				okText="Copy images"
				cancelText="Cancel"          
				width={461}
				>
					<Row style={{marginBottom: "20px", textAlign: "center" }}>
						<Col span={24}>
							<Text>Selected images will be copied to another image bank. <br/> <strong>Please select the image bank to copy them.</strong> </Text>					              
						</Col>
					</Row>
					<Row>
						<Col span={20} offset={2}>
						<Select 
							style={{ marginTop:'5px', width: '100%' }}
							placeholder="Select an Image from the Image Bank"
							onChange={onChange}
							>
								{allImages.map(image =>(
									<Option value={image.key}>
										<Space size={1} direction="vertical">                                                               
											<Text>{`${image.name}`}</Text> 
										</Space>
									</Option>
								))}
						</Select>
						</Col>
					</Row>
			</Modal>

			<Modal
				className="clean-modal modal-small-title"
				title={`Assign Metaphors`}
				visible={metaphorsVisible}
				onOk={handleOkMetaphors}
				onCancel={handleCancelMetaphors}
				okText="Assign"
				cancelText="Cancel"          
				width={461}
				>
					<Row style={{marginBottom: "15px", textAlign: "center" }}>
						<Col span={24}>
							<Text>Assign Deep and / or Thematic Metaphor to all selected images </Text>					              
						</Col>
					</Row>
					<Row style={{marginBottom: "15px" }}>					
						<Col span={20} offset={2}>
							<Text>Deep Metaphor</Text>	
							<IconSelect value={currenThematicMpetaphorModal} handleChange={statusModalChangeMetaphors } options={MetaphorsData} defaultSelected={all} />
						</Col>
					</Row>
					<Row>
						<Col span={20} offset={2}>
							<Text>Thematic Metaphor</Text>							
							<IconSelect value={currentThemathicsModal} handleChange={statusChangeThemathicModal}  options={thematicMpetaphorModal} defaultSelected={all}/>
						</Col>
					</Row>					
			</Modal>												                 
		</div>
	)
}

export default ImageBankFilters;
