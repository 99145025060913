import { Breadcrumb, Button, Col, Input, message, Modal, Row, Typography } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import Loading from '../Loading/Loading';
import { useFirestore } from 'reactfire';
import { UserContext } from '../../providers/UserProvider';

const ImageBankHeader = ({name, id, images, notes}) => {
	const {Text} = Typography;
	const [visibleDuplicate, setVisibleDuplicate] = useState(false);
	const [duplicateName, setDuplicateName] = useState('');
	const { user } = React.useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const firestore = useFirestore();
	const history = useHistory();

	const showDuplicateModal = () => {
		setVisibleDuplicate(true);
	};

	const handleCancelDuplicate = () => {
		setVisibleDuplicate(false);
	};

	const handleOkDuplicate = async () => {
		setVisibleDuplicate(false);

		if (duplicateName === '') {
			message.warning('Please, input the image bank name');

			return;
		}

		setLoading(true);

		const values = {
			name: duplicateName,
			'creation_date': new Date(),
			'edit_date': new Date(),
			'edited_by':  user.name,
			notes: notes,
		};

		await firestore.collection('images').add(values)
			.then(async (docRef) => {
				console.log('Document written with ID: ', docRef.id);

				const promises = images.map(async image => {
					return await firestore.collection('images')
						.doc(docRef.id)
						.collection('bank')
						.add(image)
						.catch(() => {
							message.error('Error on copy image function');
						});
				});

				await Promise.all(promises);

				message.success('Image bank created');
				history.push(`/image-bank/${docRef.id}`);
				window.location.reload();
			})
			.catch(() => {
				message.error('Error creating the image bank');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<Row align="middle" justify="space-between">
			<Col>
				<Breadcrumb
					style={{ marginBottom: '20px', marginTop: '20px', fontSize: '30px' }}
				>
					<Breadcrumb.Item>
						<Link to="/image-bank">Image Bank</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						{name}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col>
				<Row>
					<Col style={{ marginRight: '10px'}}>
						<Button onClick={showDuplicateModal} type="secondary">
							<CopyOutlined /> Duplicate
						</Button>
					</Col>
					<Col>
						<Link to={`/image-bank/edit/${id}`}>
							<Button type="secondary">
								<EditOutlined /> Edit Image Bank
							</Button>
						</Link>
					</Col>
				</Row>
			</Col>

			<Modal
				className="clean-modal"
				title="Duplicate image bank"
				visible={visibleDuplicate}
				onOk={handleOkDuplicate}
				onCancel={handleCancelDuplicate}
				okText="Duplicate"
				cancelText="Cancel"
				width={461}
			>
				<Row style={{marginBottom: '20px' }} justify="center">
					<Col>
						<Text style={{fontSize: '16px'}}>
							You cannot save two image banks with the same name.<br/> <strong>Please save this image bank with a new name.</strong>
						</Text>
					</Col>
				</Row>
				<Row>
					<Col offset={1} span={16}>
						<Text style={{ fontWeight: 600}}>
							Image bank name
						</Text>
					</Col>
				</Row>
				<Row >
					<Col offset={1} span={22}>
						<Input onChange={(e)=>setDuplicateName(e.target.value)} placeholder={`${name} copy`}/>
					</Col>
				</Row>
			</Modal>
		</Row>
	);
};

export default ImageBankHeader;
