import React, {useState} from 'react'
import CollasableHeader from "./CollasableHeader"
import Animate from 'rc-animate'
import { Col, Row } from 'antd';

const Collasable = (props) => {
    const { children, title, subtitle, opened , unbox, className, size} = props
    const [panelOpen, setPanelOpen] = useState(opened)

    const onChange = (checked) => {
        setPanelOpen(checked)
    }

    const spanSize = () => {
        if (typeof size != 'undefined' && size === 'small') {
            return 18
        }
        return 24
    }

    const offSetSize = () => {
        if (typeof size != 'undefined' && size === 'small') {
            return 3
        }
        return 0
    }    
    return (
        <div className={`Collasable`}>
            <CollasableHeader subtitle={subtitle} title={title} defaultChecked={opened} onChange={onChange} />
                    <Animate
                        transitionName="fade"
                        transitionEnter
                        transitionLeave>
                        {panelOpen ? 
                            <div className={`${className} ${unbox ? `` : 'box-content '}`} style={{ marginTop: "10px"}}>
                                <Row>
                                    <Col offset={offSetSize()} span={spanSize()}>                                
                                        {children}
                                    </Col>
                                </Row>                                
                            </div>  
                            : null              
                        }
                    </Animate>
        </div>
    )
}

export default Collasable
