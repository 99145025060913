import './ImageBanksList.scss';
import { Button, Col, Layout, Row, Typography } from 'antd';
import ImagesIndexList from '../../components/ImagesIndexList/ImagesIndexList';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import React from 'react';
import SearchImages from '../../components/SearchImages/SearchImages';
import SimileHeader from '../../components/Header/SimileHeader';
import { useImageBanksList } from './useImageBanksList';
import { withRouter } from 'react-router';

const ImageBanksList: React.FC = () => {
	const { Content } = Layout;
	const { Title } = Typography;
	const {
		images,
		loading,
	} = useImageBanksList();

	if (loading) {
		return <Loading />;
	}

	return (
		<Layout className="layout" style={{ paddingTop: '64px' }}>
			<SimileHeader />
			<Content className="container" >
				<Row align="middle" justify="space-between" style={{ marginTop: '28px' }} >
					<Col>
						<Title style={{ marginBottom: '0px' }}>Image Banks</Title>
					</Col>
					<Col>
						<Link to="/image-bank/create">
							<Button type="primary">+ Image Bank</Button>
						</Link>
					</Col>
				</Row>
				<SearchImages onSearch={(): null => null} images={images} />
				<ImagesIndexList images={images} />
			</Content>
		</Layout>
	);
};

export default withRouter(ImageBanksList);
