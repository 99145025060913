import './ImagesRowDetail.scss';
import { Col, Row, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CollapseImageRow from '../CollapseImageRow/CollapseImageRow';
import StatusCircle from '../StatusCircle/StatusCircle';
import { useFirestore } from 'reactfire';

const ImagesRowDetail = ({ image, index, bank}) => {
	const { id } = useParams();
	const { Text, Title } = Typography;
	const { name, idFirebase } = image;
	const firestore = useFirestore();
	const [isUsed, setIsUsed ] = useState(false);

	useEffect(() => {
		const checkStatus = async () => {
			let imageId = id;

			if (typeof idFirebase != 'undefined') {
				imageId = idFirebase;
			}

			const projectsRef = await firestore.collection('projects').where('image_bank', '==', imageId).limit(1).get();

			if (typeof projectsRef !== 'undefined' && projectsRef.size > 0) {
				setIsUsed(true);
			}
		};

		checkStatus();
	}, [firestore, id, idFirebase]);

	return (
		<div className="ImageDetailRow">

			<Row align="middle" gutter={[10, 10]}>
				<Col span={12} >
					<Text type="secondary" className="subtitle">
                        NAME
					</Text>
					<Link to={idFirebase ? `/image-bank/${idFirebase}` : '#'}><Title level={3}>{name}</Title></Link>
				</Col>

				<Col span={4}>
					<Text type="secondary" className="subtitle">
                        IMAGES
					</Text>
					<Title ellipsis={true} level={3}>{typeof bank != 'undefined' ? bank.length : 0}</Title>
				</Col>

				<Col span={6}>
					<Row align="top">
						<Col span={10} style={{paddingLeft:'30px'}}>
							<Text type="secondary" className="subtitle">
                                Status
							</Text>
							<div style={{ marginTop: '12px' }}>
								<StatusCircle height="9px" color={isUsed ? '#1765AD' : '#979797'}/>
								<Text>{isUsed ? 'In Use' : 'Not Used'}</Text>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>

			<CollapseImageRow index={index} image={image} />
		</div>
	);
};

export default ImagesRowDetail;
