import { useFirestore, useFirestoreDocData, useRemoteConfig } from 'reactfire';
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { UserContext } from '../../providers/UserProvider';

interface UseEditImageStore {
    handleCancel: () => void;
    handleOk: () => void;
    letters: number;
    loadingButton: boolean;
    onCancel: () => void;
    onFinish: (value: any) => void;
    questions: number;
    setLetters: (value: number) => void;
    visibleModal: boolean;
    image: any;
    deleteImageBank: () => void;
}

export const useEditImage = (): UseEditImageStore => {
	const history = useHistory();
	const { id }: {id: string} = useParams();
	const [letters, setLetters] = React.useState(0);
	const [visibleModal, setVisibleModal] = React.useState(false);
	const [loadingButton, setLoadingButton] = React.useState(false);
	const [showFields] = React.useState(false);
	const [questions] = React.useState(0);

	const firestore = useFirestore();
	const imagesQuery = firestore.collection('images').doc(id);
	const image: any = useFirestoreDocData(imagesQuery);
	const { user } = React.useContext(UserContext);
	const remoteConfig = useRemoteConfig();

	remoteConfig.defaultConfig = {
		'qualtrics_function_surveys_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/surveys',
	};
	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	const onCancel = (): void => {
		if (showFields) {
			setVisibleModal(true);
		} else {
			history.push(`/image-bank/${id}`);
		}
	};

	const handleOk = (): void => {
		history.push('/');
	};

	const handleCancel = (): void => {
		setVisibleModal(false);
	};

	const onFinish = (values: any): void => {
		setLoadingButton(true);
		values['creation_date'] = new Date();
		values['edit_date'] = new Date();
		values['edited_by'] = user.name;

		if (typeof values.default === 'undefined') {
			values.default = '';
		}

		if (typeof values.status === 'undefined') {
			values.status = '';
		}

		firestore.collection('images')
			.doc(id)
			.update(values)
			.then(() => {
				history.push(`/image-bank/${id}`);
				setLoadingButton(false);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setLoadingButton(false);
			});
	};

	const deleteImageBank = (): void => {
		firestore.collection('images')
			.doc(id)
			.delete()
			.then(() => {
				history.push('/image-bank');
			}).catch((error) => {
				console.error('Error removing document: ', error);
			});
	};

	return {
		onFinish,
		setLetters,
		deleteImageBank,
		onCancel,
		loadingButton,
		visibleModal,
		handleOk,
		handleCancel,
		letters,
		questions,
		image,
	};
};

