export const RatingsType = {
	usable: 'Usable',
	notUsable: 'Not Usable',
	missing: 'Missing Metaphor',
};

export const Rating = [
	{
		value: RatingsType.usable,
	},
	{
		value: RatingsType.notUsable,
	},
	{
		value: RatingsType.missing,
	},
];
