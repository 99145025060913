import React from "react";
import { Collapse } from "antd";
import HeaderCollapseResponseIndex from "../HeaderCollapseResponseIndex/HeaderCollapseResponseIndex";
import PanelResponseIndex from "../PanelResponseIndex/PanelResponseIndex";
import { DownCircleTwoTone } from "@ant-design/icons";

const CollapseResponseIndex = ({index, project, analysts}) => {
  const { Panel } = Collapse
  return (
    <div className="CollapseResponseIndex">
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={null}
        bordered={false}
        expandIcon={({ isActive }) => (
          <DownCircleTwoTone
            twoToneColor="#2074C1"
            rotate={isActive ? -180 : 0}
          />
        )}
      >
        <Panel header={<HeaderCollapseResponseIndex index={index} project={project} />} key="1">
          <PanelResponseIndex analysts={analysts} project={project} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapseResponseIndex;
