import { Progress } from 'antd';
import React from 'react';

const ProgressBar = (props) => {
	const {percent, status} = props;

	return (
		<div>
			<Progress percent={percent} status={status} />
		</div>
	);
};

export default ProgressBar;
