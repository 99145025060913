import './ResponseIndexProject.scss';
import { Col, Modal, Row, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import CollapseResponseIndex from '../CollapseResponseIndex/CollapseResponseIndex';
import IconSelect from '../Select/IconSelect';
import ProgressBar from '../ProgressBar/ProgressBar';
import StatusCircle from '../StatusCircle/StatusCircle';
import { useFirestore } from 'reactfire';
import { UserContext } from '../../providers/UserProvider';

const ResponseIndexProject = ({ project, index, analysts}) => {
	const { id } = useParams();
	const { Text, Title } = Typography;
	const { survey, name, client, status, simile_status: simileStatus, totalCompleted, target } = project;
	const firestore = useFirestore();
	const [value, setValue] = useState(simileStatus);
	const { user } = useContext(UserContext);
	const [visible, setVisible] = useState(false);
	const [proposedValue, setProposeValue] = useState('');

	const statusType = {
		open: 'Active',
		archived: 'Archived',
		completed: 'Completed',
	};

	const statusSelect = [
		{
			value: statusType.open,
			color: '#2074C1',
		},
		{
			value:statusType.completed,
			color: '#4cb865',
		},
		{
			value: statusType.archived,
			color: '#979797',
		},
	];

	const updateStatus = (value) => {
		firestore.collection('projects')
			.doc(id)
			.update({'simile_status': value, 'edited_date': new Date(), 'edited_by': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', id);
				setValue(value);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setValue(status.archived);
			});
	};

	const handleChange = (value) => {
		setVisible(true);
		setProposeValue(value);
	};

	const handleOk = () => {
		updateStatus(proposedValue);
		setVisible(false);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const getStatusColor = (type) => {
		const current = statusSelect.find(statu => statu.value === type);

		return current.color;
	};

	return (
		<div className="ResponseIndexProject">

			<Row align="middle" style={{marginBottom: 10}} gutter={[10, 10]}>
				<Col span={10} >
					<Text type="secondary" className="subtitle">
						PROJECT NAME
					</Text>
					<Link to={`/project/${survey}`}><Title level={3}>{name}</Title></Link>
				</Col>

				{index
					? <Col span={4}>
						<Text type="secondary" className="subtitle">
							CLIENT
						</Text>
						<Title ellipsis={true} style={{fontWeight:'bold'}} level={3}>{client}</Title>
					</Col>

					: null
				}

				<Col offset={!index ? 4 : 0} span={10}>
					<Row align="top">

						{index
							? <>
								<Col span={10} style={{paddingLeft:'30px'}}>
									<Text type="secondary" className="subtitle">
										Status
									</Text>
									<div style={{ marginTop: '12px' }}>
										<StatusCircle width="9px" height="9px" color={simileStatus ? getStatusColor(simileStatus) : '#979797'}/>
										<Text>{simileStatus ? simileStatus : 'N/A'}</Text>
									</div>
								</Col>
							</>

							: <>
								<Col span={10} >
									<Text type="secondary" className="subtitle">
										Status
									</Text>
									<div style={{ marginTop: '10px' }}>
										<IconSelect handleChange={handleChange} value={value} options={statusSelect} />
									</div>
								</Col>
							</>
						}
						<Col span={14} style={{paddingLeft:'30px'}} >
							<Col>
								<Text type="secondary" className="subtitle">
									Progress
								</Text>
								<div style={{ marginTop: '15px' }}>
									<ProgressBar status={status} percent={Math.floor((totalCompleted * 100) / parseInt(target))} />
								</div>
							</Col>
						</Col>
					</Row>
				</Col>
			</Row>

			{index
				? null

				: <>
					<Text type="secondary" className="subtitle">
						Client Name
					</Text>
					<Title level={4} style={{marginTop: '5px'}}>{client}</Title>
				</>
			}

			<CollapseResponseIndex index={index} analysts={analysts} project={project} />

			<Modal
				title="Status change request"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Yes, change it "
				cancelText="No, cancel"
			>
				<Text align="center">
					<p>Are you sure you want to change the <br/>
					status of the project <strong>{name}</strong> to <strong>{proposedValue}</strong></p>
				</Text>
			</Modal>
		</div>
	);
};

export default ResponseIndexProject;
