import React from "react";
import { Collapse } from "antd";
import HeaderCollapseResponseIndex from "../HeaderCollapseResponseIndex/HeaderCollapseResponseIndex";
import PanelResponseIndex from "../PanelResponseIndex/PanelResponseIndex";
import { DownCircleTwoTone } from "@ant-design/icons";
import HeaderImageRowCollapse from '../HeaderImageRowCollapse/HeaderImageRowCollapse';
import ImageRowPanelDetail from '../ImageRowPanelDetail/ImageRowPanelDetail';

const CollapseImageRow = ({index, image, analysts}) => {
  const { Panel } = Collapse
  return (
    <div style={ {marginTop: '10px'} } className="CollapseResponseIndex">
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={null}
        bordered={false}
        expandIcon={({ isActive }) => (
          <DownCircleTwoTone
            twoToneColor="#2074C1"
            rotate={isActive ? -180 : 0}
          />
        )}
      >
        <Panel header={<HeaderImageRowCollapse index={index} image={image} />} key="1">
          <ImageRowPanelDetail image={image} />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapseImageRow;
