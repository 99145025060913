import { Button, Col, Form, Input, InputNumber, Layout, Row, Select, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import React from 'react';
import SimileHeader from '../../components/Header/SimileHeader';
import { useEditProject } from './useEditProject';
import { withRouter } from 'react-router';

const EditProject: React.FC = () => {
	const { Content } = Layout;
	const { Title, Text } = Typography;
	const { Option } = Select;
	const { TextArea } = Input;
	const {
		loading,
		project,
		onFinish,
		onChange,
		onChangeTextArea,
		filterAnalyst,
		analysts,
		loadingButton,
		id,
		showFields,
		letters,
		imageBank,
		user,
	} = useEditProject();

	if (loading) {
		return <Loading />;
	}

	return (
		<Layout className="layout" style={{ paddingTop: '64px' }}>
			<SimileHeader />
			<Content className='container'>
				<Title style={{ marginBottom: '24px', marginTop: '28px', textAlign: 'center' }} level={2}>{'Let\'s get this project set up'}</Title>

				<Form
					name="basic"
					initialValues={{
						'image_bank': project.image_bank,
						target: project.target,
						notes: project.notes,
						client: project.client,
						'questions_number': project.questions_number,
						analyst: project.analyst.filter((analyst: string) => analyst !== user.email),
						survey: (
							<Row>
								<Col span={12}>
									<Text strong>{project.name}</Text>
								</Col>
								<Col span={12}>
									<Text type="secondary">ID:</Text> <Text> {project.survey}</Text>
								</Col>
							</Row>
						),
					}}
					onFinish={onFinish}
					onFinishFailed={(errorInfo): void => console.error('Failed:', errorInfo)}
				>

					<div className="box-content container" style={{ minHeight: '420px' }}>
						<Row>
							<Col span={12} offset={6}>

								<Space size={0} direction="vertical" style={{ marginBottom: '14px', marginTop: '43px' }}>
									<Text strong><span className="star">*</span> Select the survey you want to connect</Text>
									<Text type="secondary">Choose one from the list of active surveys on Qualtrics</Text>
								</Space>
								<Form.Item
									name="survey"
									rules={[{ required: true, message: 'Plase select a survey' }]}
								>
									<Select
										style={{ width: '100%' }}
										disabled
									>
									</Select>
								</Form.Item>
							</Col>
						</Row >
						<React.Fragment>

							<Row>
								<Col span={12} offset={6}>
									<Text strong ><span className="star">*</span> Project Client</Text>
									<Form.Item
										name="client"
										rules={[{ required: true, message: 'Please input the client name' }]}
									>
										<Input onChange={onChange} style={{ marginTop: '6px' }} placeholder="Placeholder" />
									</Form.Item>
								</Col>
							</Row>

							<Row>
								<Col span={12} offset={6}>
									<Space size={4} direction="vertical">
										<Text strong><span className="star">*</span> Target Coded Responses</Text>
										<Form.Item
											name="target"
											rules={[{ type: 'number', required: true, message: 'Please input the target responses' }]}
										>
											<InputNumber onChange={onChange} min={0} placeholder="Number" />
										</Form.Item>
									</Space>
								</Col>
							</Row>

							<Row>
								<Col span={12} offset={6}>
									<Space size={4} direction="vertical">
										<Text strong><span className="star">*</span> Number of Simile Questions</Text>
										<Form.Item
											name="questions_number"
											rules={[{ type: 'number' }, { required: true, message: 'Please input the number of questions' }]}
										>
											<InputNumber onChange={onChange} min={0} placeholder="Number" />
										</Form.Item>
									</Space>
								</Col>
							</Row>

							<Row>
								<Col span={12} offset={6}>
									<Text strong> Project Notes </Text>
									<Form.Item
										name="notes"
										rules={[{ min: 0, max: 200 }]}
									>
										<TextArea
											style={{ marginTop: '6px', height: '106px', borderRadius: '6px' }}
											className="textArea-Input"
											maxLength={200}
											onChange={(e): void => onChangeTextArea(e.target.value.length)}
										/>
									</Form.Item>
									<Text
										style={{ marginTop: '-25px', marginBottom: '25px' }}
										className='letterCount'
										strong > <span>200 character limit</span> {letters} <span>/200</span></Text>
								</Col>
							</Row>

							<Row>
								<Col span={12} offset={6}>
									<Text strong > <span className="star">*</span> ISE image bank URL</Text>
									<Form.Item
										name="image_bank"
										rules={[{ min: 0, max: 200 }]}
									>
										<Select
											style={{ marginTop: '5px', width: '100%' }}
											placeholder="Select an Image from the Image Bank"
											optionFilterProp="children"
											onChange={onChange}
										>
											{imageBank.map((image, index) => (
												<Option value={image.idFirebase} key={index}>
													<Space size={1} direction="vertical">
														<Text>{`${image.name}`}</Text>
													</Space>
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>

							<Row style={{ marginBottom: '22px' }} >
								<Col span={12} offset={6}>
									<Text strong > <span className="star">*</span> Invite Analyst</Text>
									<Form.Item
										name="analyst"
										rules={[{ required: true }]}
									>
										<Select
											mode="multiple"
											className="analyst-select"
											style={{ marginTop: '5px', width: '100%' }}
											placeholder="Select an analyst"
											optionFilterProp="children"
											filterOption={filterAnalyst}
											onChange={onChange}
										>
											{analysts.map(analyst => (
												<Option value={analyst.email} key={analyst.name}>
													<Space size={2} direction="vertical">
														<Text>{`${analyst.name} ${analyst.lastname}`}</Text>
														<Text type="secondary">{analyst.email}</Text>
													</Space>
												</Option>
											))}

										</Select>
									</Form.Item>
								</Col>
							</Row>
						</React.Fragment>
					</div>
					<Row justify="center" style={{ marginTop: '30px', textAlign: 'center' }}>
						<Col span={2}><Link to={`/project/${id}`}><Button type="link">Cancel</Button></Link></Col>
						<Col span={2}><Button loading={loadingButton} type="primary" htmlType="submit" disabled={!showFields}>Save project</Button></Col>
					</Row>
				</Form>
			</Content>
		</Layout>
	);
};

export default withRouter(EditProject);
