import { useFirestore, useRemoteConfig } from 'reactfire';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

interface UseCreateImageStore {
    handleCancel: () => void;
    handleOk: () => void;
    letters: number;
    loadingButton: boolean;
    onCancel: () => void;
    onFinish: (value: any) => void;
    questions: number;
    setLetters: (value: number) => void;
    visibleModal: boolean;
}

export const useCreateImage = (): UseCreateImageStore => {
	const history = useHistory();
	const [visibleModal, setVisibleModal] = React.useState(false);
	const [loadingButton, setLoadingButton] = React.useState(false);
	const [showFields] = React.useState(false);
	const [letters, setLetters] = React.useState(0);
	const [questions] = React.useState(0);
	const remoteConfig: firebase.remoteConfig.RemoteConfig = useRemoteConfig();

	const firestore = useFirestore();
	const { user } = React.useContext(UserContext);

	remoteConfig.defaultConfig = {
		'qualtrics_function_surveys_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/surveys',
	};
	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	const onCancel = (): void => {
		if (showFields) {
			setVisibleModal(true);
		} else {
			history.push('/image-bank');
		}
	};

	const handleOk = (): void => {
		history.push('/image-bank');
	};

	const handleCancel = (): void => {
		setVisibleModal(false);
	};

	const onFinish = (values: any): void => {
		setLoadingButton(true);
		values['creation_date'] = new Date();
		values['edit_date'] = new Date();
		values['edited_by'] = user.name;
		firestore.collection('images')
			.add(values)
			.then(() => {
				history.push('/image-bank');
				setLoadingButton(false);
			})
			.catch((error) => {
				console.error('Error adding document: ', error);
				setLoadingButton(false);
			});
	};

	return {
		handleCancel,
		handleOk,
		letters,
		loadingButton,
		onCancel,
		onFinish,
		questions,
		setLetters,
		visibleModal,
	};
};
