import './ResponseDetail.scss';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { CommentOutlined, StarFilled, StarOutlined, StarTwoTone } from '@ant-design/icons';
import React, { Fragment, useContext, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Link } from 'react-router-dom';
import Metaphor from '../Metaphors/Metaphor';
import Moment from 'react-moment';
import { Sentiments } from '../../data/Sentiments';
import { Status } from '../../data/Status';
import StatusCircle from '../StatusCircle/StatusCircle';
import { UpdateProjectEdition } from '../../tools/UpdateProjectEdition';
import { UserContext } from '../../providers/UserProvider';

const ResponseDetail = ({ response, project}) => {
	const { user } = useContext(UserContext);
	const firestore = useFirestore();
	const [hover, setHover] = useState(false);
	const { Text } = Typography;
	const { linkSelected} = response;
	const { questions } = project;
	const [ responseStarred, setResponseStarred ] = useState(response.simile_starred);

	const numberFormater = (number) => {
		if (typeof number === 'undefined' || Number.isNaN(number)) {
			return '000';
		}

		const str = number.toString();

		return str.length < 3 ? numberFormater('0' + str) : str;
	};

	const getStatusColor = (type) => {
		const current = Status.find(statu => statu.value === type);

		if (typeof current != 'undefined') {
			return current.color;
		}

		return '';
	};

	const getSentimentIcon = (sentiment) => {
		if (typeof sentiment != 'undefined') {
			const currentIcon = Sentiments.filter(obj => obj.value.toLowerCase() === sentiment.toLowerCase());

			if (typeof currentIcon[0] !== 'undefined') {
				return currentIcon[0].icon;
			}
		}
	};

	const getSentiment = (value) => {
		if (typeof value != 'undefined') {
			const current = Sentiments.filter(obj => obj.value.toLowerCase() === value.toLowerCase());

			if (current.length > 0) {
				return current[0].value;
			}

			return Sentiments[0].value;
		}

		return '';
	};

	const {idFirebase} = response;
	const commentsQuery = firestore.collection('responses').doc(idFirebase).collection('comments');
	const comments = useFirestoreCollectionData(commentsQuery, {idField: 'idFirebase'});

	const setStarred = (e) => {
		e.stopPropagation();
		e.preventDefault();

		let valueStarred = !response.simile_starred;

		if (typeof response.simile_starred === 'undefined') {
			valueStarred = true;
		}

		firestore.collection('responses')
			.doc(response.idFirebase)
			.update({
				'simile_starred': valueStarred,
				'edit_date': new Date(),
				'edit_user': user.name,
				'edit_user_id': user.uid,
			})
			.then(() => {
				console.log('Document written with ID: ', response.idFirebase);
				UpdateProjectEdition(firestore, user, response.surveyId);
				response['simile_starred'] = valueStarred;
				setResponseStarred(valueStarred);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	return (
		<div className="ResponseDetail">
			<div className="box-content">
				<Text style={{color: '#1765AD', fontWeight: '900'}} strong>#{numberFormater(response.simile_index)}</Text>
				<Divider
					type="vertical"
					style={{ height: '24px', borderColor: '#D9D9D9', margin: '0 16px' }}
				/>
				<Text className="font-weight-normal subtitle-large">
					{typeof questions != 'undefined' ? questions[response.moduleNumber ?? 0] : 'No question provided' }
				</Text>
				<div onClick={setStarred}>
					<div className="starred-icon" onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)}>
						{responseStarred ? (
							<StarFilled style={{ color: '#FFA524'}} />
						) : (
							<Fragment>
								{hover ? (
									<StarTwoTone twoToneColor="#FFA524" />
								) : (
									<StarOutlined style={{ color: '#C0BDBD' }} />
								)}
							</Fragment>
						)}
					</div>
				</div>
				<Row gutter={[16, 0]} style={{ marginTop: '9px' }}>
					<Col flex="0 1 195px">
						<img
							srcSet={linkSelected}
							className="image-preview-response"
							alt="responseImage"
						/>
					</Col>
					<Col flex="1 1 300px">
						<Row className="status-row">
							<Col flex="0 1 230px">
								<Text type="secondary" className="subtitle">
								Status
								</Text>
								<div style={{ marginTop: '8px' }}>
									<StatusCircle width={'9px'} height="9px" color={response.simile_status ? getStatusColor(response.simile_status) : '#979797'} />
									<Text>{response.simile_status ? response.simile_status : ''}</Text>
								</div>
							</Col>
							<Col flex="0 1 175px">
								<Text type="secondary" className="subtitle">
								Deep Metaphor
								</Text>
								<Row>
									<Col>
										<div style={{ marginTop: '8px' }}>
											{typeof response.simile_metaphor != 'undefined' ? (
												<Metaphor active={true} data={response.simile_metaphor}/>
											) : (
												<Metaphor active={true} unselected/>
											)}
										</div>
									</Col>
								</Row>
							</Col>
							<Col flex="0 1 200px">
								<Text type="secondary" className="subtitle">
									Thematic Metaphor
								</Text>
								<Row>
									<Col>
										<div style={{ marginTop: '8px' }}>
											{typeof response.simile_thematicpmetaphor != 'undefined' ? (
												<Metaphor active={true} label={response.simile_thematicpmetaphor} color={response.simile_metaphor.color}/>
											) : (
												<Metaphor active={true} unselected/>
											)}
										</div>
									</Col>
								</Row>
							</Col>
							<Col flex="0 1 200px" style={{alignItems: 'end', textAlign: 'right'}}>
								{!!comments.length && (
									<Link to={{
										pathname: `/response/${response.idFirebase}`,
										showComments: true,
									}}>
										<Space size={5} align="end" style={{height: '100%'}}>
											<CommentOutlined style={{color: '#979797'}} />
											<Text>{comments.length} comments</Text>
										</Space>
									</Link>
								)}
							</Col>
						</Row>
						<Row className="info-row">
							<Col flex="0 1 230px">
								<Text type="secondary" className="subtitle">
									Sentiment
								</Text>
								{getSentimentIcon(response.simile_sentiment ?? response.sentimentLabel)}
								<Text>{getSentiment(response.simile_sentiment ?? response.sentimentLabel)}</Text>
							</Col>
							<Col flex="0 1 175px">
								<Text type="secondary" className="subtitle">
									Rating
								</Text>
								<Text>{response.simile_rating}</Text>
							</Col>
							<Col flex="0 1 auto">
								<Text type="secondary" className="subtitle">
								Last Edit
								</Text>
								<Text>
									{typeof response.edit_user != 'undefined' ? (
										<Fragment>
											<Moment format="YYYY/MM/DD">{response.edit_date && response.edit_date._seconds * 1000}</Moment>
											{` by ${response.edit_user}`}
										</Fragment>
									) : (
										'N/A'
									)}
								</Text>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ResponseDetail;
