import React, {useEffect, useState} from "react";
import { Select, Typography } from "antd";
import StatusCircle from "../StatusCircle/StatusCircle";
import "./IconSelect.scss";

const IconSelect = (props) => {
  const { Option } = Select;
  const { Text } = Typography;
	const { options, style, handleChange, value, defaultSelected, disabled, mode} = props;

  return (
    <div className="IconSelect" style={style}>
      <Select mode={mode || null} disabled={disabled} defaultValue={defaultSelected ? defaultSelected.value : ""} style={{ width: "100%" }} value={value} onChange={handleChange}>
        {defaultSelected && 
              <Option key={defaultSelected.key} value={defaultSelected.value}>
                <Text>{defaultSelected.value}</Text>
              </Option>    
        }
        {options && options.length && options.map((el) => (
          <Option key={el.key ?? el.value} value={el.value}>
            {el.color && <StatusCircle color={el.color} />}
            <Text>{el.value}</Text>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default IconSelect;
