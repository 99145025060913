import { useFirestore, useUser } from 'reactfire';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../interfaces/User';
import { UserUpdateContext } from '../../providers/UserProvider';

interface UseAccountSetupStore {
    loadingButton: boolean;
    onFinish: (value: any) => void;
}

export const useAccountSetup: () => UseAccountSetupStore = () => {
	const [loadingButton, setLoadingButton] = React.useState(false);
	const history = useHistory();
	const userFirebase: User | firebase.User = useUser();
	const firestore: firebase.firestore.Firestore = useFirestore();
	const { setUser } = React.useContext(UserUpdateContext);

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'account-setup',
		});
	}, []);

	const getUserLetters = (user: string): string => {
		const matches = user.match(/\b(\w)/g);
		const acronym = matches ? matches.join('') : '';
		const initials = acronym.substring(0, 2);

		return initials;
	};

	const onFinish = async (values: any): Promise<void> => {
		setLoadingButton(true);
		values.email = userFirebase.email ? userFirebase.email : '';
		values.initials = getUserLetters(`${values.name} ${values.lastname}`);
		values.color = `#${Math.floor(Math.random()*16777215).toString(16)}`;
		values.uid = userFirebase.uid;
		firestore.collection('users')
			.doc(userFirebase.uid)
			.set(values)
			.then(() => {
				setUser({
					uid: values.uid,
					initials: values.initials,
					name: `${values.name} ${values.lastname}`,
					email: values.email,
					color: values.color,
					lastname: values.lastname,
				});
				history.push('/');
				setLoadingButton(false);
			})
			.catch(() => {
				setLoadingButton(false);
				history.push('/not-found');
			});
	};

	return {
		loadingButton,
		onFinish,
	};
};

