import { Button, InputNumber, Space, Typography } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const ResponseNavigator = ({project, current, max}) => {
	const history = useHistory();
	const { Text } = Typography;
	const [defaultValue, setDefaultValue] = useState(current);
	const [defaultPrevValue, setDefaultPrevValue] = useState(current);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			let goTo = event.target.value;

			if (event.target.value > max) {
				goTo = max;
			}

			if (event.target.value < 0) {
				goTo = 1;
			}

			history.push(`/redirect/${project}/${goTo}`);
		}
	};

	const redirectTo = () => {
		history.push(`/redirect/${project}/${defaultValue}`);
	};

	const redirectToPrevious = () => {
		history.push(`/redirect/${project}/${defaultPrevValue}`);
	};

	useEffect(() => {
		const next = current + 1;
		const prev = current - 1;

		if (next > max) {
			setDefaultValue(1);
		} else {
			setDefaultValue(next);
		}

		if (prev < 1) {
			setDefaultPrevValue(max);
		} else {
			setDefaultPrevValue(prev);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ResponseNavigator">
			<Space direction="horizontal">
				<Text>Go to</Text>
				<InputNumber min={1} onKeyDown={handleKeyDown} max={max} value={defaultValue} />
				<Button onClick={redirectToPrevious} type="primary">
					<DoubleLeftOutlined /> Prev
				</Button>
				<Button onClick={redirectTo} type="primary">
					<DoubleRightOutlined /> Next
				</Button>
			</Space>
		</div>
	);
};

export default ResponseNavigator;
