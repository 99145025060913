import './Response.scss';
import { Divider, message } from 'antd';
import React, { Fragment, useContext } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import AdditionalMetaphors from '../Metaphors/AdditionalMetaphors';
import ImageDescription from '../ImageDescription/ImageDescription';
import Metaphors from '../Metaphors/Metaphors';
import RatingStatus from '../Response/RatingStatus';
import { RatingsType } from '../../data/Rating';
import { StatusType } from '../../data/Status';
import { UpdateProjectEdition } from '../../tools/UpdateProjectEdition';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const Response = (props) => {
	const { style, response, project, comments } = props;
	const {questions} = project;
	const { id } = useParams();
	const firestore = useFirestore();
	const { user } = useContext(UserContext);
	const queryAdditionals = firestore.collection('responses').doc(id).collection('additionals_metaphors').orderBy('edit_date');
	const additionals = useFirestoreCollectionData(queryAdditionals, {idField: 'idFirebase'});

	const openNotification = type => {
		message.success(`${type} Saved`);
	};

	const updatePrimaryMetaphor = (metaphor) => {
		const refResponse = firestore.collection('responses').doc(id);

		refResponse
			.update({'simile_metaphor': metaphor, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', metaphor.key);

				refResponse
					.update({'simile_thematicpmetaphor': ''})
					.then(() => {
						console.log('Document written with ID: ', 'clean thematic');
						openNotification('Primary metaphor');
					})
					.catch((error) => {
						console.error('Error updating document: ', error);
					});

				UpdateProjectEdition(firestore, user, response.surveyId);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	const updateThematicMetaphor = (value) => {
		firestore.collection('responses')
			.doc(id)
			.update({'simile_thematicpmetaphor': value, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', value);
				UpdateProjectEdition(firestore, user, response.surveyId);
				openNotification('Thematic metaphor');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	const addAdditionalMetaphor = () => {
		firestore.collection('responses')
			.doc(id)
			.collection('additionals_metaphors')
			.add({
				key: 'na',
				color: '#FFFFFF',
				label: '',
				thematic: [],
				'edit_date': new Date(),
			})
			.then(() => {
				console.log('Document written with ID: ', id);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	const onMetaphorClick = (metaphor) => {
		updatePrimaryMetaphor(metaphor);
	};

	const onThematicMetaphorClick = (metaphor) => {
		updateThematicMetaphor(metaphor);
	};

	const numberFormater = (number) => {
		if (typeof number === 'undefined' || Number.isNaN(number)) {
			return '000';
		}

		const str = number.toString();

		return str.length < 3 ? numberFormater('0' + str) : str;
	};

	const toogleUsableState = () => {
		if (typeof response.simile_rating != 'undefined' && (response.simile_rating === RatingsType.notUsable || response.simile_rating === RatingsType.missing)) {
			return 'disabled-container out-of-container';
		}

		return '';
	};

	const showNotificationState = () => {
		if (typeof response.simile_status != 'undefined' && response.simile_status === StatusType.completed) {
			message.info('This answer is complete, to edit it you have to change the status.');
		}
	};

	const showRatingNotificationState = () => {
		if (typeof response.simile_rating != 'undefined' && response.simile_rating === RatingsType.notUsable) {
			message.info('This answer is as unusable, please first mark it as usable to be able to edit the deep metaphors.');
		}
	};

	return (
		<Fragment>
			<div className={'response-box'} style={style}>
				<div className="box-content">
					<ImageDescription
						imageId={numberFormater(response.simile_index)}
						title={typeof questions != 'undefined' ? questions[response.moduleNumber ?? 0] : 'No question provided'}
						image={response.linkSelected}
						comments={comments}
						project={project}
						response={response}
					>
						<p>
							{response.description_en}
						</p>
						<p>
							{response.explanation_en}
						</p>
					</ImageDescription>
				</div>
				<div onClick={showNotificationState} >
					<div className={`box-content ${response.simile_status} ${response.simile_status === StatusType.completed ? 'disabled-container' : ''}`}>
						<RatingStatus response={response} id={id} />
						<Divider />
						<div onClick={showRatingNotificationState}>
							<div className={toogleUsableState()}>
								<Metaphors response={response} addAdditionalMetaphor={addAdditionalMetaphor} onThematicMetaphorClick={onThematicMetaphorClick} onMetaphorClick={onMetaphorClick} />
								{additionals.length > 0 && (
									<Fragment>
										<Divider />
										<AdditionalMetaphors additionals={additionals} id={id} />
									</Fragment>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>

	);
};

export default Response;
