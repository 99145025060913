import React, { Fragment } from "react";
import { List, Comment, Avatar } from "antd";
import moment from 'moment';
import CommentWithInput from '../CommentWithInput/CommentWithInput'

const CommentList = (props) => {
  const { comments, query} = props

  return (
    <Fragment>
    {comments.map((comment) => (
        <Fragment>
            <CommentWithInput query={query} comment={comment} />
        </Fragment>
    ))}
    </Fragment>
  );
};

export default CommentList;
