export const StatusType = {
	open: 'Open',
	completed: 'Completed',
	forReview: 'For Review',
};

export const Status = [
	{
		value: StatusType.open,
		color: '#2074C1',
	},
	{
		value: StatusType.completed,
		color: '#4cb865',
	},
	{
		value: StatusType.forReview,
		color: '#fadb14',
	},
];
