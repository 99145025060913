import React from 'react'
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useFirestore } from 'reactfire';

const DeleteAdditional = ({id, additional}) => {
    const firestore = useFirestore()

    const remove = () => {
        firestore.collection('responses')
        .doc(id)
        .collection('additionals_metaphors')
        .doc(additional.idFirebase) 
        .delete()
        .then(function() {
            console.log("Document successfully deleted!");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });   
    }

    return (
        <div className="delete-additional">
            <Button size="small" onClick={remove} shape="circle" icon={<CloseOutlined />} />
        </div>
    )
}

export default DeleteAdditional
