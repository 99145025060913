import './PanelResponseIndex.scss';
import { Button, Col, Input, Row, Select, Space, Typography } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useFirestore, useRemoteConfig } from 'reactfire';
import Moment from 'react-moment';
import { UserContext } from '../../providers/UserProvider';

const PanelResponseIndex = ({project, analysts}) => {
	const { Text, Paragraph } = Typography;
	const { TextArea } = Input;
	const firestore = useFirestore();
	const { user } = useContext(UserContext);
	const [loadingExportSimile, setLoadingExportSimile] = useState(false);
	const [ imageBankName, setImageBankName ] = useState();
	const [ imageBankURL, setImageBankURL ] = useState();
	const remoteConfig = useRemoteConfig();

	remoteConfig.defaultConfig = {
		'function_download_raw_responses_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/downloadRawResponses',
		'function_download_exported_responses_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/downloadExportedResponses',
	};
	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	const { Option } = Select;

	const {
		notes,
		questions_number: questionsNumber,
		creation,
		survey,
		image_bank: imageBank,
		analyst,
	} = project;

	useEffect(() => {
		if (imageBank.includes('http')) {
			setImageBankName(imageBank);
			setImageBankURL(imageBank);
		} else {
			firestore.collection('images')
				.doc(imageBank)
				.get()
				.then((querySnapshot) => {
					const imageBankData = querySnapshot.data();

					setImageBankName(imageBankData.name);
					setImageBankURL(`/image-bank/${imageBank}`);
				})
				.catch((error) => {
					console.log('Error getting documents: ', error);
				});
		}
	}, [imageBankName]);

	const downloadExported = async () => {
		setLoadingExportSimile(true);

		try {
			await remoteConfig.fetchAndActivate();
		} catch (err) {
			console.error(err);
		}

		setLoadingExportSimile(false);

		const downloadURL = remoteConfig.getString('function_download_exported_responses_url');
		const win = window.open(`${downloadURL}?surveyId=${project.survey}`, '_blank');

		win.focus();
	};

	const downloadRaw = async () => {
		setLoadingExportSimile(true);

		try {
			await remoteConfig.fetchAndActivate();
		} catch (err) {
			console.error(err);
		}

		setLoadingExportSimile(false);

		const downloadURL = remoteConfig.getString('function_download_raw_responses_url');
		const win = window.open(`${downloadURL}?surveyId=${project.survey}`, '_blank');

		win.focus();
	};

	const onChange = (value) => {
		firestore.collection('projects')
			.doc(survey)
			.update({analyst: value, 'edited_date': new Date(), 'edited_by': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', survey);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	const filterAnalyst = (input, option) => {
		try {
			return option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		} catch (error) {
			return false;
		}
	};

	return (
		<div className="PanelResponseIndex">
			<Row>
				<Col span={14}>
					<Text type="secondary" className="subtitle">
						Project Notes
					</Text>
					<TextArea style={{lineHeight: '1.3em'}} rows={3} value={notes} disabled></TextArea>
				</Col>
				<Col span={8} offset={1}>
					<Row className="project-info">
						<Col flex="0 1 150px">
							<Text type="secondary" className="subtitle">
								# of simile questions
							</Text>
						</Col>
						<Col flex="0 1 auto">
							<Text strong>{questionsNumber}</Text>
						</Col>
					</Row>
					<Row className="project-info">
						<Col flex="0 1 150px">
							<Text type="secondary" className="subtitle">
								Date created
							</Text>
						</Col>
						<Col flex="0 1 auto">
							<Text strong><Moment format="YYYY/MM/DD">{creation}</Moment></Text>
						</Col>
					</Row>
					<Row className="project-info">
						<Col flex="0 1 150px">
							<Text type="secondary" className="subtitle">
								Qualtrics survey ID
							</Text>
						</Col>
						<Col flex="0 1 auto">
							<Text strong>{survey}</Text>
						</Col>
					</Row>
					<Row className="project-info">
						<Col flex="0 1 150px">
							<Text type="secondary" className="subtitle">
								Image bank URL
							</Text>
						</Col>
						<Col flex="0 1 auto">
							<a rel="noopener noreferrer" href={imageBankURL} target="_blank">
								{imageBankName}
							</a>
						</Col>
					</Row>
				</Col>
			</Row>
			<div style={{ marginTop: '15px' }}>
				<Row align="bottom">
					<Col span={14}>
						<Paragraph
							type="secondary"
							style={{ marginBottom: '7px' }}
							className="subtitle"
						>
							Analysts
						</Paragraph>
						<Select
							mode="multiple"
							style={{ marginTop:'5px', width: '100%' }}
							placeholder="Select an analyst"
							optionFilterProp="children"
							onChange={onChange}
							className="analyst-select"
							defaultValue={analyst}
							filterOption={filterAnalyst}
						>
							{
								analysts.map(analyst => (
									<Option value={analyst.email} key={analyst.email}>
										<Space size={2} direction="vertical">
											<Text>{`${analyst.name} ${analyst.lastname}`}</Text>
											<Text type="secondary">{analyst.email}</Text>
										</Space>
									</Option>
								))
							}
						</Select>
					</Col>
					<Col span={10} align="right">
						<Space>
							<Button onClick={downloadRaw}>
								<DownloadOutlined /> Download raw responses
							</Button>
							<Button
								loading={loadingExportSimile}
								onClick={downloadExported}
							>
								<UploadOutlined /> Export coded Simile data
							</Button>
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default PanelResponseIndex;
