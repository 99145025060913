import './ResponseIndex.scss';
import { Layout } from 'antd';
import Loading from '../../components/Loading/Loading';
import ProjectHeader from '../../components/ProjectHeader/ProjectHeader';
import React from 'react';
import ResponseIndexProject from '../../components/ResponseIndexProject/ResponseIndexProject';
import ResponsesList from '../../components/ResponsesList/ResponsesList';
import SimileHeader from '../../components/Header/SimileHeader';
import { useResponseIndex } from './useResponseIndex';

const ResponseIndex: React.FC = () => {
	const { Content } = Layout;
	const {
		pagination,
		loading,
		project,
		analysts,
		responses,
		setTotalPages,
		setCurrentPage,
		setSort,
		sort,
	} = useResponseIndex();

	if (loading) {
		return (<Loading />);
	}

	return (
		<div className="ResponseIndex">
			<Layout className="layout" style={{ paddingTop: '64px' }}>
				<SimileHeader />
				<Content
					className="container"
				>
					<ProjectHeader id={project.survey} name={project.name} />
					<div className="box-content" style={{ marginBottom: '40px' }}>
						<ResponseIndexProject
							analysts={analysts}
							project={project}
							index={false}
						/>
					</div>
					<ResponsesList pagination={pagination} commentsRef={''} responses={responses} project={project} setTotalPages={setTotalPages} setCurrentPage={setCurrentPage} sort={sort} setSort={setSort} />
				</Content>
			</Layout>
		</div>
	);
};

export default ResponseIndex;
