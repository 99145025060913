import React from 'react'
import './PreviewBox.scss'
import { Row, Col, Typography } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import Metaphor from '../Metaphors/Metaphor';
import StatusCircle from '../StatusCircle/StatusCircle';
import { Status } from '../../data/Status'

const PreviewBox = ({response, tooglePreview}) => {
    const { Text } = Typography
    const history = useHistory()

    const getStatusColor = (type) => {
        const current = Status.find(statu => statu.value === type)
        if (typeof current != 'undefined') {
            return current.color
        } 
        return ""
    }

    const numberFormater  = (number) => {
        if (typeof number === 'undefined' || Number.isNaN(number)) {
            return "000"
          }        
        let str = number.toString()
        return str.length < 3 ? numberFormater("0" + str) : str
    }    

    const handleClick = () => {
        tooglePreview()
        //history.push(`/response/${response.idFirebase}`)
        history.push(`/redirect/${response.surveyId}/${response.simile_index}`)
    }

    return (
        <Row align="center">
            <Col>
                <Link onClick={handleClick}>
                    <div className="preview-box">
                        <Row align="center" justify="space-between">
                            <Col>
                                <Text strong style={{color: '#1890ff'}}>#{numberFormater(response.simile_index)}</Text>
                            </Col>
                            <Col>
                                <StatusCircle width={"9px"} height="9px" color={response.simile_status ? getStatusColor(response.simile_status) : '#979797'} />
                                <Text>{response.simile_status ? response.simile_status : ''}</Text>
                            </Col>
                        </Row>
                        <Row align="center" justify="space-between">
                            <Col>
                            <img className="preview-image" srcSet={response.linkSelected} />
                            </Col>
                        </Row>   
                        <Row align="center" justify="space-between">
                            <Col>
                                {response.id}
                            </Col>
                            <Col>
                                {typeof response.simile_metaphor != 'undefined' ?
                                    <Metaphor active={true} data={response.simile_metaphor}/>
                                :
                                    <Metaphor active={true} unselected/>
                                }
                            </Col>
                        </Row>                                     
                    </div>                 
                </Link>           
            </Col>
        </Row>
    )
}

export default PreviewBox
