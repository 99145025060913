import React from 'react';
import './StatusCircle.scss';

const StatusCircle = (props) => {
	const {color, width, height} = props;
	return (
		<div className="StatusCircleContainer">
			<div className="StatusCircle">
				<div style={{borderRadius: '50%', backgroundColor: color, width, height}}>
				</div>
			</div>
		</div>
	)
}

StatusCircle.defaultProps = {
	width: '9px',
	height: '9px',
	color: '#979797'
}

export default StatusCircle
