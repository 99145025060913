import './ImageDescription.scss';
import { Button, Col, Divider, Input, message, Modal, Row, Space, Typography } from 'antd';
import React, { Fragment, useState } from 'react';
import { Fade } from 'react-reveal';
import { FullscreenOutlined } from '@ant-design/icons';
import StatusHeader from '../Response/StatusHeader';

const ImageDescription = (props) => {
	const { Text, Link} = Typography;
	const {style, title, imageId, image, children, idLink, response, comments, project} = props;
	const [isShown, setIsShown] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);

	const cleanTitle = (link) => {
		let name = link.substring(link.lastIndexOf('/') + 1);

		name = name.replace('-', ' ');
		name = name.replace('_', ' ');
		name = name.replace('.jpg', '');

		return name;
	};

	const copyToClipboard = (image) => {
		navigator.clipboard.writeText(image);
		message.success('Your link was copied to the clipboard');
	};

	return (
		<Fragment>
			<StatusHeader project={project} comments={comments} response={response} style={{ marginBottom: '10px'}}/>
			<div className="ImageDescription" style={style}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<Space>
							{idLink ?
								<Link href={idLink} strong># {imageId}</Link>
								:
								<Text strong># {imageId}</Text>
							}
							<Divider type="vertical" />
							<Text className="font-weight-normal subtitle-large">
								<div dangerouslySetInnerHTML={{ __html: title }} />
							</Text>
						</Space>
					</Col>
				</Row>
				<Row gutter={[16, 8]}>
					<Col justi="center" align="middle" flex="0 1 342px">
						<div className="img-description-container"
							onMouseEnter={() => setIsShown(true)}
							onMouseLeave={() => setIsShown(false)}
						>
							<Fade when={isShown}>
								<div className="overlay-img-description"></div>
								<div className="link-input">
									<Space justify="center" direction="hozisontal">
										<Input defaultValue={image} style={{ width: '223px' }} /><Button type="primary" onClick={() => {copyToClipboard(image);}} style={{ width: '79px' }}>Copy</Button>
									</Space>
								</div>
							</Fade>
							<img srcSet={image} className="img-description" alt="responseImage"/>
							<Button onClick={() => setModalVisible(!modalVisible)} style={{ position: 'absolute', right: '10px', bottom: '10px' }} icon={<FullscreenOutlined /> } />
						</div>

					</Col>
					<Col flex="1 1 300px">
						<div className="answer-description">
							<Text>
								{children}
							</Text>
						</div>
					</Col>
				</Row>

				<Modal
					title={cleanTitle(image)}
					centered
					width={867}
					visible={modalVisible}
					onCancel={() => setModalVisible(false)}
					footer={[]}
				>
					<p><img srcSet={image} className="modal-image" alt="responseImage"/></p>
				</Modal>
			</div>
		</Fragment>
	);
};

export default ImageDescription;
