import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { useParams } from 'react-router-dom';

interface UseImageBankDetailStore {
    id: string;
    imageBank: any;
    imageBankImages: firebase.firestore.DocumentData[];
    allImages: firebase.firestore.DocumentData[];
}

export const useImageBankDetail = (): UseImageBankDetailStore => {
	const { id }: {id: string} = useParams();
	const firestore = useFirestore();
	const query = firestore.collection('images').doc(id);
	const imageBankQuery = firestore.collection('images').doc(id);
	const imageBank = useFirestoreDocData(query);
	const imageBankImages = useFirestoreCollectionData(imageBankQuery.collection('bank'), {idField: 'key'});
	const allImagesQuery = firestore.collection('images');
	const allImages = useFirestoreCollectionData(allImagesQuery, {idField: 'key'});

	return {
		id,
		imageBank,
		imageBankImages,
		allImages,
	};
};
