import { Button, Col, Divider, Empty, Pagination, Row, Space, Switch, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ResponseFilterContext, ResponseFilterUpdateContext } from '../../providers/ResponseFilterProvider';
import { useFirestore, useFirestoreCollectionData} from 'reactfire';
import Animate from 'rc-animate';
import { Parser } from 'json2csv';
import ProjectComments from '../Project/ProjectComments';
import ResponseDetail from '../ResponseDetail/ResponseDetail';
import ResponseIndexFilter from '../ResponseIndexFilter/ResponseIndexFilter';
import ResponseIndexSort from '../ResponseIndexSort/ResponseIndexSort';
import { UploadOutlined } from '@ant-design/icons';

const ResponsesList = ({pagination, project, responses, commentsRef, setTotalPages, setCurrentPage, sort, setSort}) => {
	const { id } = useParams();

	const { Title, Text } = Typography;
	const [showComment, setShowComment] = useState(false);

	const { toggleFilters } = useContext(ResponseFilterContext);
	const { setToggleFilters } = useContext(ResponseFilterUpdateContext);

	const [allResponses, setAllResponses] = useState([]);
	const firestore = useFirestore();

	const commentsQuery = firestore.collection('projects').doc(id).collection('comments');
	const comments = useFirestoreCollectionData(commentsQuery, {idField: 'idFirebase'});

	const toggleComment = (checked) => {
		setShowComment(checked);
	};

	const toggleFilter = (checked) => {
		setToggleFilters(checked);
	};

	const onFiltered = (isFilteredEnabled) => {
		toggleFilter(isFilteredEnabled);
	};

	const onPaginationChange = (page, pageSize) => {
		setCurrentPage(page);
		setTotalPages(pageSize);
	};

	const download = (filename, text) => {
		const element = document.createElement('a');

		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
		element.setAttribute('download', filename);
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	};

	const downloadComments = () => {
		commentsQuery
			.get()
			.then((querySnapshot) => {
				const clean = querySnapshot.docs.map(doc => doc.data());

				if (clean.length > 0) {
					const json2csvParser = new Parser();
					const csv = json2csvParser.parse(clean);

					download('comments-exported.csv', csv);
				}
			})
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};

	useEffect(() => {
		setAllResponses(responses);
	}, [responses]);

	const onSort = (value) => {
		setSort(value);
	};

	return (
		<Fragment>
			<Fragment>
				<div style={{ marginBottom: '24px' }}>
					<ResponseIndexSort showFilter={toggleFilters} onChange={toggleFilter} sort={sort} onSort={onSort} title="Responses" />
				</div>
				<Animate transitionName="fade" transitionAppear>
					<div style={{ marginBottom: '23px', display: toggleFilters ? 'block' : 'none' }}>
						<ResponseIndexFilter onFilter={onFiltered} />
					</div>
				</Animate>
				<div style={{ marginBottom: '31px' }}>
					<Text className="subtitle-large" strong>
						{pagination.total}
					</Text>
					<Text className="subtitle-large font-weight-normal">
						{' '}
						responses found for your research
					</Text>
				</div>
			</Fragment>
			{typeof allResponses !== 'undefined' && allResponses.length > 0 ? (
				<Fragment>
					{typeof responses != 'undefined' &&
						responses.map(response => (
							<div style={{ marginBottom: '20px' }} key={response.idFirebase}>
								<Link
									className="inactive-link"
									to={{
										pathname: `/response/${response.idFirebase}`,
										showComments: false,
									}}
								>
									<ResponseDetail project={project} response={response} />
								</Link>
							</div>
						))
					}
					<Row style={{ marginTop: '20px', marginBottom: '30px' }}>
						<Col span={24} align="right">
							<div className="paginator-container">
								<Pagination pageSize={pagination.size} showQuickJumper defaultCurrent={1} current={pagination.page} total={pagination.total} showSizeChanger onChange={onPaginationChange}/>
							</div>
						</Col>
					</Row>
					<div id="ProyectComment" className="box-content">
						<Row align="middle" justify="space-between">
							<Col>
								<Space align="center" size={20}>
									<Title style={{fontWeight: '700', marginTop: '10px'}} level={3}>{comments.length} Comments in this project</Title>
									<Button onClick={downloadComments}>
										<UploadOutlined /> Export comments
									</Button>
								</Space>
							</Col>
							<Col>
								<Space size={18}>
									<Text strong>Show comments</Text>
									<Switch defaultChecked={false} onChange={toggleComment} />
								</Space>
							</Col>
						</Row>
						<Animate transitionName="fade" transitionAppear>
							{showComment && (
								<Row>
									<Col offset={3} span={18}>
										<ProjectComments commentsRef={commentsRef} project={project} query={commentsQuery} comments={comments} style={{ marginBottom: '20px'}} />
										<Divider />
										<Row justify="center">
											<Col>
												<Pagination defaultCurrent={1} total={project.totalComments} showSizeChanger/>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						</Animate>
					</div>
				</Fragment>
			) : (
				<Empty style={{marginTop: '20px'}} description={<span>No answers in this survey yet</span>}/>
			)}
		</Fragment>
	);
};

export default ResponsesList;
