import './ResponseIndexFilter.scss';
import { Button, Col, InputNumber, Row, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ResponseFilterContext, ResponseFilterUpdateContext } from '../../providers/ResponseFilterProvider';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import _ from 'lodash';
import IconSelect from '../Select/IconSelect';
import { MetaphorsData } from '../../data/MetaphorsData';
import { Mixpanel } from '../../tools/MixPanel';
import { Rating } from '../../data/Rating';
import ResetButton from '../ResetButton/ResetButton';
import { Sentiments } from '../../data/Sentiments';
import { Status } from '../../data/Status';

const ResponseIndexFilter = ({ onFilter }) => {
	const { Text } = Typography;

	const firestore = useFirestore();
	const queryAnalysts = firestore.collection('users');
	const analysts = useFirestoreCollectionData(queryAnalysts);

	const all = {
		value: 'All',
		key: 'all',
	};

	const { filters } = useContext(ResponseFilterContext);
	const { setFilters } = useContext(ResponseFilterUpdateContext);
	const [users, setUsers] = useState();
	const [currentStatus, setCurrentStatus] = useState(filters.currentStatus);
	const [deepMetaphor, setDeepMetaphor] = useState(filters.deepMetaphor);
	const [thematicMpetaphor, setThematicMetaphor] = useState(filters.thematicMpetaphor);
	const [sentiment, setSentiment] = useState(filters.sentiment);
	const [currentRating, setCurrentRating] = useState(filters.currentRating);
	const [lastEditedBy, setLastEditetBy] = useState(filters.lastEditedBy);
	const [simileLoop, setSimileLoop] = useState(filters.simileLoop);
	const [currentStarred, setCurrentStarred] = useState(filters.currentStarred);
	const [currentThemathics, setCurrentThematics] = useState(filters.currentThemathics);
	const [responsesFrom, setResponsesFrom] = useState(filters.responsesFrom);
	const [responsesTo, setResponsesTo] = useState(filters.responsesTo);

	useEffect(() => {
		const preUsers = [];

		analysts.map(analyst => {
			const user = {
				value: `${analyst.name} ${analyst.lastname}`,
				color: analyst.color,
			};

			preUsers.push(user);
		});
		setUsers(preUsers);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const starred = [
		{ value: 'True' },
		{ value: 'False' },
	];

	const resetValues = () => {
		setCurrentStatus(all.value);
		setDeepMetaphor(all.value);
		setThematicMetaphor(all.value);
		setCurrentThematics([]);
		setSentiment(all.value);
		setCurrentRating(all.value);
		setLastEditetBy(all.value);
		setCurrentStarred(all.value);
		setResponsesFrom(null);
		setResponsesTo(null);
	};

	const statusChangeStatus = (value) => {
		setCurrentStatus(value);
	};

	const statusChangeSentiments = (value) => {
		setSentiment(value);
	};

	const statusChangeThemathic = (value) => {
		setThematicMetaphor(value);
	};

	const statusChangeRating = (value) => {
		setCurrentRating(value);
	};

	const statusChangeUsers = (value) => {
		setLastEditetBy(value);
	};

	const statusChangeLoop = (value) => {
		setSimileLoop(value);
	};

	const statusChangeStarred = (value) => {
		setCurrentStarred(value);
	};

	const capitalize = (str) => {
		const splitStr = str.toLowerCase().split(' ');

		for (let i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}

		return splitStr.join(' ');
	};

	const statusChangeMetaphors = (value) => {
		setCurrentThematics([]);
		setThematicMetaphor(all.value);

		const isAll = value === all.value;

		if (!((isAll && value.length > 2) || value.length === 0)) {
			const primary = MetaphorsData.filter( (thematic) => {
				return thematic.value.toLowerCase() === value.toLowerCase();
			});

			const thematics = primary[0].thematic.map((thematic) => {
				const value = capitalize(thematic);
				const item = {
					value: value,
				};

				return item;
			});
			const deepMeth = primary.map(el => el.value);

			setDeepMetaphor(deepMeth[0]);
			setCurrentThematics(_.flatten(thematics));

			return;
		}

		setDeepMetaphor(all.value);
	};

	const applyFilter = () => {
		setFilters({
			currentStatus,
			deepMetaphor,
			thematicMpetaphor,
			currentThemathics,
			sentiment,
			currentRating,
			lastEditedBy,
			currentStarred,
			simileLoop,
			responsesFrom,
			responsesTo,
		});

		Mixpanel.track('filter-response', {
			status: currentStatus,
			sentiment: sentiment,
			rating: currentRating,
			edited: lastEditedBy,
			deepMetaphor: deepMetaphor,
			thematicMetaphor: thematicMpetaphor,
		});

		onFilter(true);
	};

	return (
		<div className="ResponseIndexFilter">
			<Row gutter={[15, 0]} align="middle" style={{ marginBottom: '15px' }}>
				<Col span={3}>
					<Text strong>Rating</Text>
					<IconSelect value={currentRating} handleChange={statusChangeRating} options={Rating} defaultSelected={all}/>
				</Col>
				<Col span={5}>
					<Text strong>Deep Metaphor</Text>
					<IconSelect value={deepMetaphor} handleChange={statusChangeMetaphors} options={MetaphorsData} defaultSelected={all} />
				</Col>
				<Col span={5}>
					<Text strong>Thematic Metaphor</Text>
					<IconSelect value={thematicMpetaphor} handleChange={statusChangeThemathic} options={currentThemathics} defaultSelected={all}/>
				</Col>
				<Col span={5}>
					<Text strong>Sentiment</Text>
					<IconSelect value={sentiment} handleChange={statusChangeSentiments} options={Sentiments} defaultSelected={all} />
				</Col>
				<Col span={6} align="right">
					<ResetButton onClick={resetValues} />
				</Col>
			</Row>
			<Row gutter={[15, 0]} align="middle">
				<Col span={3}>
					<Text strong>Status</Text>
					<IconSelect value={currentStatus} handleChange={statusChangeStatus} options={Status} defaultSelected={all} />
				</Col>
				<Col span={3}>
					<Text strong>Starred</Text>
					<IconSelect value={currentStarred} handleChange={statusChangeStarred} options={starred} defaultSelected={all} />
				</Col>
				<Col span={6}>
					<Text strong>Last edit</Text>
					<IconSelect value={lastEditedBy} handleChange={statusChangeUsers} options={users} defaultSelected={all} />
				</Col>
				<Col span={6}>
					<Text strong>Simile loop</Text>
					<IconSelect value={simileLoop} handleChange={statusChangeLoop} options={[]} defaultSelected={all} />
				</Col>
				<Col span={6}>
					<Text strong>Responses Between</Text>
					<Row gutter={[6, 0]} align="middle">
						<Col span={6}>
							<InputNumber onChange={(e) => setResponsesFrom(e)} value={responsesFrom} />
						</Col>
						<Col span={3} style={{ textAlign: 'center' }}>
							<Text>and</Text>
						</Col>
						<Col span={6}>
							<InputNumber onChange={(e) => setResponsesTo(e)} value={responsesTo} />
						</Col>
						<Col span={4}>
							<Button type="primary" onClick={applyFilter}>
								Apply
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default ResponseIndexFilter;
