import { Alert, Button, Col, Form, Input, Layout, Row, Space, Typography } from 'antd';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import React from 'react';
import SimileLogo from '../../assets/images/simile-logo.svg';
import { useRecover } from './useRecover';
import { UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';

const Signup: React.FC = () => {
	const { Content } = Layout;
	const { Text } = Typography;
	const {
		loadingButton,
		error,
		success,
		onFinish,
	} = useRecover();

	return (
		<Layout style={{ height: '100vh' }}>
			<Content
				style={{
					padding: '0 50px',
					marginTop: 40,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<div
					style={{
						background: '#fff',
						padding: 24,
						height: 450,
						width: 400,
						textAlign: 'center',
						flexDirection: 'column',
						justifyContent: 'center',
						display: 'flex',
					}}
				>
					<Fade>
						<Row justify="center" style={{marginBottom: '20px'}}>
							<Col style={{textAlign: 'center'}}>
								<Space direction="vertical">
									<Text>Simile Analys Tool</Text>
									<img srcSet={SimileLogo} className="logo-main" alt="Logo" />
								</Space>
							</Col>
						</Row>
						{success && <Alert message={success} type="success" showIcon closable/> }
						{error && <Alert message={error} type="error" showIcon closable/> }
						<Form className="login-form"
							initialValues={{ remember: false }}
							onFinish={onFinish}
							onFinishFailed={(errorInfo): void => console.log('Failed:', errorInfo)}
							layout="vertical"
						>
							<Form.Item
								name="email"
								label="Email"
								rules={[{ required: true }]}
							>
								<Input
									prefix={
										<UserOutlined
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									placeholder="Email"
								/>
							</Form.Item>
							<Form.Item>
								<Link to="/login">Back to login‎</Link>
							</Form.Item>
							<Form.Item>
								<Button
									loading={loadingButton}
									type="primary"
									htmlType="submit"
									className="login-form-button"
									style={{ marginRight: 10 }}
								>
									Recover my password
								</Button>
							</Form.Item>
						</Form>
					</Fade>
				</div>
			</Content>
		</Layout>
	);
};

export default withRouter(Signup);
