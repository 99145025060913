import React, { useState, useEffect } from 'react'
import { Space } from 'antd'
import Metaphor from "./Metaphor"
import './DeepMetaphors.scss'
import { MetaphorsData } from '../../data/MetaphorsData'

const DeepMetaphors = ({onMetaphorClick, response, additional, current}) => {

    const onClick = (metaphor) => {
        if (typeof additional != 'undefined') {
            metaphor.additional = response.idFirebase
        }         
        onMetaphorClick(metaphor)
    }

    return (
        <Space>
            {MetaphorsData.map(metaphor => {
                return <Metaphor data={metaphor} active={metaphor.key === current ? true : false} onMetaphorClick={onClick} value={metaphor.key} />
            })}
        </Space>
    )
}

export default DeepMetaphors
