import ImageBankDetailTable from '../../components/ImageBankDetailTable/ImageBankDetailTable';
import ImageBankHeader from '../../components/ImageBankHeader/ImageBankHeader';
import ImageIndexListSingle from '../../components/ImagesIndexList/ImageIndexListSingle';
import { Layout } from 'antd';
import Loading from '../../components/Loading/Loading';
import React from 'react';
import SimileHeader from '../../components/Header/SimileHeader';
import { useImageBankDetail } from './useImageBankDetail';

const ImageBankDetail: React.FC = () => {
	const { Content } = Layout;
	const [loading, setLoading] = React.useState(false);
	const {
		id,
		imageBank,
		imageBankImages,
		allImages,
	} = useImageBankDetail();

	if (loading) {
		return <Loading />;
	}

	return (
		<Layout className="layout" style={{ paddingTop: '64px' }}>
			<SimileHeader />
			<Content className="container" >
				<ImageBankHeader notes={imageBank.notes} images={imageBankImages} name={imageBank.name} id={id} />
				<ImageIndexListSingle images={imageBank} bank={imageBankImages} />
				<ImageBankDetailTable setLoading={setLoading} allImages={allImages} name={imageBank.name} bank={imageBankImages} id={id} />
			</Content>
		</Layout>
	);
};

export default ImageBankDetail;
