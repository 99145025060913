import React, { useState } from 'react';

interface ResponseFilterStore {
	filters: any;
	toggleFilters: boolean;
}

interface ResponseFilterUpdateStore {
	setFilters: (filters: any) => void;
	setToggleFilters: (toggleFilters: boolean) => void;
}

const all = {
	value: 'All',
	key: 'all',
};

const defaultValue = {
	currentStatus: all.value,
	deepMetaphor: all.value,
	thematicMpetaphor: all.value,
	currentThemathics: [],
	sentiment: all.value,
	currentRating: all.value,
	lastEditedBy: all.value,
	currentStarred: all.value,
	simileLoop: all.value,
	responsesFrom: null,
	responsesTo: null,
};

export const ResponseFilterContext = React.createContext({} as ResponseFilterStore);
export const ResponseFilterUpdateContext = React.createContext({} as ResponseFilterUpdateStore);

export const ResponseFilterProvider: React.FC = props => {
	const [filters, setFilters] = useState(defaultValue);
	const [toggleFilters, setToggleFilters] = useState(false);

	const responseFilterStore: ResponseFilterStore = {
		filters,
		toggleFilters,
	};

	const setResponseFilter: ResponseFilterUpdateStore = {
		setFilters,
		setToggleFilters,
	};

	return (
		<ResponseFilterContext.Provider value={responseFilterStore}>
			<ResponseFilterUpdateContext.Provider value={setResponseFilter}>
				{props.children}
			</ResponseFilterUpdateContext.Provider>
		</ResponseFilterContext.Provider>
	);
};
