import React, { useState, useEffect } from 'react';
import "./SearchImages.scss" 

import { Layout, Typography, Space, Row, Col, Select, Button, Input, DatePicker } from 'antd'
import { Mixpanel } from '../../tools/MixPanel';
import IconSelect from '../Select/IconSelect';

const SearchImages = ({onSearch, images}) => {
    const { Title, Text } = Typography;
    const { Search } = Input;
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const [search, setSearch] = useState('')
    const [startDate, setInitDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('')    
    const [value, setValue] = useState('All')

    const onCalendarChange = (date) => {
        if (date[0] != null) {
            setInitDate(date[0])
        } else {
            setInitDate('')
        }
        if (date[1] != null) {
            setEndDate(date[1])
        } else {
            setEndDate('')
        }    
    }


	const all = {
		value: "All",
		key: "all"
	}

    const ImageBankStatusType = {
		inUse: 'In Use',
		notUsed: 'Not Used',
		missing: 'Missing Metaphor'
	}    
		  
    const statusImageBank = [
		  {
			value: ImageBankStatusType.inUse
		  },
		  {
			value: ImageBankStatusType.notUsed
		  }		  
	]    
    
    const handleChange = (value) => {
        // setStatus(value)
        // setValue(value)
    }

    const onInitSearch = (value) => {
        setSearch(value)
    }

    const applySearch = () => {
        // let projectsFiltered = projects

        // if (search != '') {
        //     projectsFiltered = projectsFiltered.filter(project => project.name.toUpperCase().includes(search.toUpperCase()) || project.client.toUpperCase().includes(search.toUpperCase())   )
        // }

        // if (status != '') {
        //     projectsFiltered = projectsFiltered.filter(project => project.simile_status == status)            
        // }

        // if (startDate != '') {         
        //     projectsFiltered = projectsFiltered.filter(project => {
        //         const creationDate = new Date(project.creation) 
        //         if (creationDate >= startDate) {
        //             return project
        //         }
        //     })    
        // }        

        // if (endDate != '') {         
        //     projectsFiltered = projectsFiltered.filter(project => {
        //         const creationDate = new Date(project.creation) 
        //         if (creationDate <= endDate) {
        //             return project
        //         }
        //     })    
        // }        

		// Mixpanel.track('filter-project', {
		// 	search: search,
		// 	status: status,
		// 	startDate: startDate,
		// 	endDate: endDate,
        // })
                
        // onSearch(projectsFiltered)
    }

    useEffect(() => {
        applySearch()
    }, [status, search, startDate, endDate])



  const statusType = {
    open: 'Active',
    archived: 'Archived',
    completed: 'Completed'
}

    return ( 
        <Row justify="space-between" className="searchBar container" style={{marginTop: '26px', marginBottom:'20px', padding: '14px 20px' }} >
            
            <Col span={12}>
                <Row>
                    <Col span={15} style={{marginLeft:'5px'}} >
                    <Space direction="vertical" >
                        <Text strong>Name</Text>
                        <Search allowClear placeholder="Search" onSearch={value => onInitSearch(value)} style={{ width: 325 }}/>
                    </Space>
                    </Col>
                    <Col span={6} >
                        <Space direction="vertical" >
                            <Text strong>Status</Text>
                            <IconSelect value={value} style={{ marginRight: 20, width: 130 }}  handleChange={handleChange} options={statusImageBank} defaultSelected={all} />
                        </Space>
                    </Col>                    
                </Row>

            </Col>
      
        </Row>
     );
}
 
export default SearchImages;