import React, { useState, useEffect } from "react"
import {Typography, Space, Row, Col, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DeepMetaphors from "./DeepMetaphors"
import ThematicMetaphors from "./ThematicMetaphors"

import { Fade } from 'react-reveal'

const { Text } = Typography

const MetaphorsImageBank = ({image, onMetaphorClick, onThematicMetaphorClick}) => {

    const [currentColor, setCurrentColor] = useState('#424B5B')
    const [thematicMetaphores, setThematicMetaphores] = useState([])

    const deepClick = (metaphor) => {
        setThematicMetaphores(metaphor.thematic)
        setCurrentColor(metaphor.color)
        onMetaphorClick(metaphor)
    }
    
    useEffect(() => {
        if (typeof image.metaphor != 'undefined') {
            console.log("metaphores: ", image.metaphor);
            setCurrentColor(image.metaphor.color)
            setThematicMetaphores(image.metaphor.thematic)
        } else {
            setThematicMetaphores([]) 
        }
    }, [image])
        
    return (
        <div className="Metaphors" style={{ marginTop: '-5px'}}>
            <Row>
                <Col span={24} style={{ marginTop: '17px'}}>
                    <Space direction="vertical" style={{ marginBottom: '14px'}}>
                        <Text className="subtitle">DEEP METAPHOR</Text>
                        <DeepMetaphors current={image.metaphor ? image.metaphor.key : ''} onMetaphorClick={deepClick} />
                    </Space>                                                                       
                </Col>
            </Row>  
            <Row>
                <Col span={24} style={{ marginTop: '5px' }}>
                    <Space direction="vertical" style={{ marginBottom: '6px'}}>
                        <Text className="subtitle">THEMATIC METAPHOR</Text>
                        <ThematicMetaphors image={image} onThematicMetaphorClick={onThematicMetaphorClick} color={currentColor} thematics={thematicMetaphores} />
                    </Space>                                                                       
                </Col>
            </Row>  
                       
        </div>
    )
}

export default MetaphorsImageBank
