import Loading from '../../components/Loading/Loading';
import React from 'react';
import { useRedirect } from './useRedirect';

const Redirect: React.FC = () => {
	useRedirect();

	return (
		<Loading />
	);
};

export default Redirect;
