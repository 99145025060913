import './ProjectIndex.scss';
import { useRemoteConfig, useUser } from 'reactfire';
import { Analyst } from '../../interfaces/Analyst';
import axios from 'axios';
import { Mixpanel } from '../../tools/MixPanel';
import { Pagination } from '../../interfaces/Pagination';
import { Project } from '../../interfaces/Project';
import React from 'react';

interface UseProjectIndexStore{
	pagination: Pagination;
	loading: boolean;
    analysts: firebase.firestore.DocumentData[];
    projects: firebase.firestore.DocumentData[];
    filteredProjects: Project[];
    onSearch: (value: Project[]) => void;
	setTotalPages: any;
	setCurrentPage: any;
}

const DefaultPaginationData: Pagination = {
	page: 1,
	size: 10,
	total: 0,
};

export const useProjectIndex = (): UseProjectIndexStore => {
	const [projects, setProjects] = React.useState<Project[]>([]);
	const [filteredProjects, setFilteredProjects] = React.useState<Project[]>([]);
	const [analysts, setAnalysts] = React.useState<Analyst[]>([]);
	const remoteConfig = useRemoteConfig();
	const [loading, setLoading] = React.useState(true);
	const [totalPages, setTotalPages] = React.useState(10);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [pagination, setPagination] = React.useState<Pagination>(DefaultPaginationData);
	const userResult: any = useUser();

	remoteConfig.defaultConfig = {
		'function_projects_list_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/projectsList',
	};

	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	const onSearch = (projectsFiltered: Project[]): void => {
		setFilteredProjects(projectsFiltered);
	};

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'project-index',
		});
	}, []);

	React.useEffect(() => {
		async function fetchData(): Promise<void> {
			setLoading(true);

			try {
				await remoteConfig.fetchAndActivate();
			} catch (err) {
				console.error(err);
				setLoading(false);
			}

			try {

				let remoteUrl = remoteConfig.getString('function_projects_list_url');

				if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
					remoteUrl = 'https://us-central1-simile-analyst-tool.cloudfunctions.net/projectsList';
				}

				const result = await axios(
					remoteUrl+`?size=${totalPages}&page=${currentPage}`,
					{
						headers: {
							Identificator: userResult.uid,
						},
					}
				);

				setProjects(result.data.projects);
				setAnalysts(result.data.analysts);
				setPagination(result.data.pagination);
				setLoading(false);

			} catch (err) {
				console.error('Error fetching projects', err);
				setLoading(false);
			}
		}

		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [remoteConfig, totalPages, currentPage]);

	return{
		pagination,
		loading,
		analysts,
		projects,
		filteredProjects,
		onSearch,
		setTotalPages,
		setCurrentPage,
	};
};
