import {Col, message, Modal, Row, Space, Typography } from 'antd';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { StarFilled, StarOutlined, StarTwoTone } from '@ant-design/icons';
import { Status, StatusType } from '../../data/Status';
import IconSelect from '../Select/IconSelect';
import { Link } from 'react-scroll';
import { RatingsType } from '../../data/Rating';
import ResponseNavigator from '../Response/ResponseNavigator';
import { UpdateProjectEdition } from '../../tools/UpdateProjectEdition';
import { useFirestore } from 'reactfire';
import { UserContext } from '../../providers/UserProvider';

const StatusNavigation = (props) => {
	const { style, response, project } = props;
	const { Text } = Typography;
	const firestore = useFirestore();
	const { user } = useContext(UserContext);
	const [value, setValue] = useState('');
	const [visible, setVisible] = useState(false);
	const [proposedValue, setProposeValue] = useState('');
	const [disableStatus, setDisableStatus] = useState(true);
	const [hover, setHover] = useState(false);
	const id = response.idFirebase;

	const openNotification = type => {
		message.success(`${type} Saved`);
	};

	const updateStatus = (value) => {
		firestore.collection('responses')
			.doc(id)
			.update({'simile_status': value, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', id);
				setValue(value);
				UpdateProjectEdition(firestore, user, response.surveyId);
				openNotification('Response Status');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setValue(Status[1].value);
			});
	};

	const handleChange = (newValue) => {
		if (newValue === StatusType.open && value === StatusType.completed) {
			setVisible(true);
			setProposeValue(newValue);

			return;
		}

		updateStatus(newValue);
	};

	const handleOk = () => {
		updateStatus(proposedValue);
		setVisible(false);
	};

	const handleCancel = () => {
		setVisible(false);
		//updateStatus(proposeValue)
	};

	const setStarred = (e) => {
		e.stopPropagation();

		let valueStarred = !response.simile_starred;

		if (typeof response.simile_starred === 'undefined') {
			valueStarred = true;
		}

		firestore.collection('responses')
			.doc(response.idFirebase)
			.update({'simile_starred': valueStarred, 'edit_date': new Date(), 'edit_user': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Document written with ID: ', response.idFirebase);
				UpdateProjectEdition(firestore, user, response.surveyId);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	useEffect(() => {
		if (response.simile_status) {
			setValue(response.simile_status);
		}
	}, [response]);

	useEffect(() => {
		setDisableStatus(response.simile_rating !== RatingsType.usable);
	}, [response.simile_rating]);

	return (
		<div className="StatusNavigation" style={style}>
			<div className="box-content">
				<Row justify="space-between" align="middle">
					<Col className={`${project.simile_status != 'Active' ? 'disabled-container' : ''}`}>
						<Space direction="vertical">
							<Text className="subtitle">status</Text>
							<IconSelect disabled={disableStatus} value={value} handleChange={handleChange} style={{width:'124px', marginTop: '-5px', marginBottom: '5px'}} options={Status} />
						</Space>
					</Col>
					<Col>
						<Space direction="horizontal">
							<ResponseNavigator max={project.totalResponses} project={response.surveyId} current={response.simile_index} />
							<Link onClick={setStarred}>
								<div className="starred-icon" onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)}>
									{response.simile_starred ?
										<StarFilled style={{ color: '#FFA524' }} />
										:
										<Fragment>
											{hover ?
												<StarTwoTone twoToneColor="#FFA524" />
												:
												<StarOutlined style={{ color: '#C0BDBD' }}/>
											}
										</Fragment>
									}
								</div>
							</Link>
						</Space>
					</Col>
				</Row>
			</div>

			<Modal
				title="Status change request"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Yes, change it "
				cancelText="No, cancel"
			>
				<Text align="center">
					<p>Are you sure you want to change the <br/>
					status of the response <strong>{response.id}</strong> to <strong>{proposedValue}</strong></p>
				</Text>
			</Modal>
		</div>
	);
};

export default StatusNavigation;
