import { Comment } from '../interfaces/Comment';
import Loading from '../components/Loading/Loading';
import React from 'react';

const commentsArr: Comment[] = [
	{
		id: 1,
		author: 'Han Solo',
		avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
		content: 'contenido',
		datetime: 'Mar 2, 2020 11:55AM',
		children: [
			{
				id: 2,
				author: 'Han Solo Hijo',
				avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
				content: 'contenido',
				datetime: 'Mar 2, 2020 11:55AM',
				children: [
					{
						id: 3,
						author: 'Han Solo Nieto',
						avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
						content: 'contenido',
						datetime: 'Mar 2, 2020 11:55AM',
						children: [],
					},
				],
			},
		],
	},
	{
		id: 4,
		author: 'Han Solo 2',
		avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
		content: 'contenido 2',
		datetime: 'Mar 2, 2020 11:55AM',
		children: [
			{
				id: 5,
				author: 'Han Solo 2 Hijo',
				avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
				content: 'contenido',
				datetime: 'Mar 2, 2020 11:55AM',
				children: [
					{
						id: 6,
						author: 'Han Solo 2 Nieto',
						avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
						content: 'contenido',
						datetime: 'Mar 2, 2020 11:55AM',
						children: [],
					},
					{
						id: 7,
						author: 'Han Solo 2 Nieto 2',
						avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
						content: 'contenido',
						datetime: 'Mar 2, 2020 11:55AM',
						children: [
							{
								id: 8,
								author: 'Han Solo Bis Nieto',
								avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
								content: 'contenido',
								datetime: 'Mar 2, 2020 11:55AM',
								children: [],
							},
						],
					},
				],
			},
		],
	},
];

interface CommentStore {
	comments: Comment[];
}

interface CommentUpdateStore {
	setComments: (comments: Comment[]) => void;
}

export const CommentProvider = React.createContext({} as CommentStore);
export const CommentUpdateContext = React.createContext({} as CommentUpdateStore);

export const CommentContext = ({ children }: any): React.FC | JSX.Element => {
	const [comments, setComments] = React.useState<Comment[]>(commentsArr);
	const [showComments, setShowComments] = React.useState(false);

	const commentStore: CommentStore = {
		comments,
	};

	const setUpdateStore: CommentUpdateStore = {
		setComments,
	};

	React.useEffect(() => {
		setComments(commentsArr);
		setShowComments(true);
	}, []);

	if (!showComments) {
		return <Loading />;
	}

	return (
		<CommentProvider.Provider value={commentStore}>
			<CommentUpdateContext.Provider value={setUpdateStore}>
				{children}
			</CommentUpdateContext.Provider>
		</CommentProvider.Provider>
	);
};
