import { Col, Empty, Pagination, Row } from 'antd';
import React, {Fragment } from 'react';
import ResponseIndexProject from '../ResponseIndexProject/ResponseIndexProject';

const ProjectIndexList = ({pagination, projects, analysts, setTotalPages, setCurrentPage}) => {
	const onChange = (page, pageSize) => {
		setTotalPages(pageSize);
		setCurrentPage(page);
	};

	if (projects === null) {
		return <div></div>;
	}

	return (
		<Fragment>
			{projects.map(project => (
				<div
					className="box-content"
					style={{marginBottom:'10px', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '26px', paddingRight: '26px'}}
					key={project.survey}
				>
					<ResponseIndexProject
						analysts={analysts}
						id={project.survey}
						project={project}
						index={true}
					/>
				</div>
			))}
			{projects.length == 0 ? (
				<Empty description={<span>No results found.</span>} />
			) : (
				<Row className="paginationContainer" align="middle" justify='end' >
					<Col>
						<Pagination
							pageSize={pagination.size}
							showQuickJumper
							defaultCurrent={pagination.page}
							total={pagination.total}
							onChange={onChange}
							showSizeChanger
						/>
					</Col>
				</Row>
			)}
		</Fragment>
	);
};

export default ProjectIndexList;
