export const UpdateProjectEdition = (firestore: any, user: {name: string; uid: string}, projectId: string): void => {
	if (typeof user != 'undefined' && user != null) {
		firestore.collection('projects')
			.doc(projectId)
			.update({'edited_date': new Date(), 'edited_by': user.name, 'edit_user_id': user.uid})
			.then(() => {
				console.log('Project written with ID: ', projectId);
				console.log('Project written by user: ', user.name);				
			})
			.catch((error: {}) => {
				console.error('Error updating document: ', error);
			});
	}
};
