import { useAuth, useFirestore, useUser } from 'reactfire';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { Store } from 'antd/lib/form/interface';
import { useHistory } from 'react-router-dom';
import { User } from '../../interfaces/User';
import { UserUpdateContext } from '../../providers/UserProvider';

interface UseLoginStore {
	error: string;
	loadingButton: boolean;
	onFinish: (values: { email: string; password: string }) => void;
}

export const useLogin = (): UseLoginStore => {
	const [error, seterror] = React.useState('');
	const [loadingButton, setLoadingButton] = React.useState(false);
	const history = useHistory();
	const auth = useAuth();
	const userFirebase: User | firebase.User = useUser();
	const firestore: firebase.firestore.Firestore = useFirestore();
	const { setUser } = React.useContext(UserUpdateContext);

	React.useEffect(() => {
		if (userFirebase) {
			const userRef = firestore.collection('users').doc(userFirebase.uid);

			userRef.get()
				.then((docSnapshot) => {
					setLoadingButton(false);

					if (docSnapshot.exists) {
						const userData: firebase.firestore.DocumentData | undefined = docSnapshot.data();

						if (userData) {
							Mixpanel.identify(userData.uid);
							Mixpanel.track('Successful login', {});
							Mixpanel.people.set({
								'$first_name': userData.name,
								'$last_name': userData.lastname,
								'USER_ID': userData.uid,
							});

							setUser({
								uid: userData.uid,
								initials: userData.initials,
								name: `${userData.name} ${userData.lastname}`,
								email: userData.email,
								color: userData.color,
								lastname: userData.lastname,
							});
							history.push('/');
						}
					} else {
						history.push('/account-setup');
					}
				});
		}
	}, [firestore, history, setUser, userFirebase]);

	const onFinish = async (values: Store): Promise<void> => {
		setLoadingButton(true);
		const { email, password } = values;

		auth.signInWithEmailAndPassword(email, password).then( () => {
			seterror('');
		}).catch( error => {
			seterror(error.message);
			Mixpanel.track('error', {
				type: 'login',
				message: error.message,
			});
			setLoadingButton(false);
		});
	};

	return {
		error,
		loadingButton,
		onFinish,
	};
};
