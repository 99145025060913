import { Col, Empty, Pagination, Row } from 'antd';
import React, {Fragment } from 'react';
import ImagesRowDetail from '../ImagesRowDetail/ImagesRowDetail';

const ImagesIndexList = ({images, pagination = true}) => {
	const onChange = (pageNumber) => {
		console.log('Page: ', pageNumber);
	};

	if (images === null) {
		return <div></div>;
	}

	return (
		<Fragment>
			{images.map((image, index) => (
				<Fragment key={index}>
					<div className="box-content" style={{marginBottom:'10px', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '26px', paddingRight: '26px'}} >
						<ImagesRowDetail
							image={image}
							bank={image.bank}
							index={true}
						/>
					</div>
				</Fragment>
			))}
			{images.length === 0 ?
				<Empty description={<span>No results found.</span>}/> :
				pagination ?
					<Row className="paginationContainer" align="middle" justify='end'>
						<Col>
							<Pagination
								showQuickJumper
								defaultCurrent={1}
								total={images.length}
								onChange={onChange}
								showSizeChanger
							/>
						</Col>
					</Row> : null
			}
		</Fragment>
	);
};

export default ImagesIndexList;
