import React from 'react';
import { User } from '../interfaces/User';

const DefaultUserData: User = {
	name: '',
	lastname: '',
	uid: '',
	initials: '',
	email: '',
	color: '',
};

interface UserStore {
	user: User;
}

interface UserUpdateStore {
	setUser: (user: User) => void;
}

export const UserContext = React.createContext({} as UserStore);
export const UserUpdateContext = React.createContext({} as UserUpdateStore);

export const UserProvider: React.FC = props => {
	const [user, setUser] = React.useState<User>(DefaultUserData);

	React.useEffect(() => {
		localStorage.setItem('localUserInformationName', user.name);
		localStorage.setItem('localUserInformationLastname', user.lastname);
		localStorage.setItem('localUserInformationUid', user.uid);
		localStorage.setItem('localUserInformationInitials', user.initials);
		localStorage.setItem('localUserInformationEmail', user.email);
		localStorage.setItem('localUserInformationColor', user.color);
	}, [user]);

	const userFromLocalStorage = (): User => {
		const newUser = DefaultUserData;

		newUser.name = localStorage.getItem('localUserInformationName') ?? '';
		newUser.lastname = localStorage.getItem('localUserInformationLastname') ?? '';
		newUser.uid = localStorage.getItem('localUserInformationUid') ?? '';
		newUser.initials = localStorage.getItem('localUserInformationInitials') ?? '';
		newUser.email = localStorage.getItem('localUserInformationEmail') ?? '';
		newUser.color = localStorage.getItem('localUserInformationColor') ?? '';

		return newUser;
	};

	const userStore: UserStore = {
		user: userFromLocalStorage(),
	};

	const setUpdateStore: UserUpdateStore = {
		setUser,
	};

	return (
		<UserContext.Provider value={userStore}>
			<UserUpdateContext.Provider value={setUpdateStore}>
				{props.children}
			</UserUpdateContext.Provider>
		</UserContext.Provider>
	);
};
