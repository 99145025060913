import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { useHistory, useParams } from 'react-router-dom';
import { Image } from '../../interfaces/Image';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { User } from '../../interfaces/User';
import { UserContext } from '../../providers/UserProvider';

interface UseEditProjectStore {
	loading: boolean;
	letters: number;
	project: any;
	loadingButton: boolean;
	onFinish: (value: any) => void;
	filterAnalyst: (input: string, option: any) => boolean;
	onChange: () => void;
	onChangeTextArea: (value: number) => void;
	analysts: any[];
	id: string;
	showFields: boolean;
	imageBank: Image[];
	user: User;
}

export const useEditProject = (): UseEditProjectStore => {
	const [letters, setLetters] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [loadingButton, setLoadingButton] = React.useState(false);
	const [showFields, setShowFields] = React.useState(false);
	const history = useHistory();
	const { user } = React.useContext(UserContext);
	const { id }: {id: string} = useParams();
	const firestore = useFirestore();
	const queryAnalysts = firestore.collection('users');
	const analysts: any[] = useFirestoreCollectionData(queryAnalysts);
	const projectQuery = firestore.collection('projects').doc(id);
	const project: any = useFirestoreDocData(projectQuery);
	const queryImageBanks = firestore.collection('images');
	const imageBank: Image[] = useFirestoreCollectionData(queryImageBanks, {idField: 'idFirebase'});

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'edit-project',
		});
	}, []);

	React.useEffect(() => {
		setLetters(project.notes.length);
		setLoading(false);
	}, [project.notes.length]);

	const onChange = (): void => {
		setShowFields(true);
	};

	const onChangeTextArea = (value: number): void => {
		setLetters(value);
		setShowFields(true);
	};

	const onFinish = (values: any): void => {
		setLoadingButton(true);
		values['edited_date'] = new Date();
		values['edited_by'] = user.name;
		values.survey = id;

		firestore.collection('projects')
			.doc(id)
			.update({ ...values, analyst: [ ...values.analyst, user.email ] })
			.then(() => {
				console.log('Document written with ID: ', id);
				history.push(`/project/${id}`);
				setLoadingButton(false);
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
				setLoadingButton(false);
			});
	};

	const filterAnalyst = (input: string, option: any): boolean => {
		return option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	};

	return {
		loading,
		project,
		onFinish,
		onChange,
		onChangeTextArea,
		filterAnalyst,
		analysts: analysts.filter(analyst => analyst.email !== user.email),
		loadingButton,
		id,
		showFields,
		letters,
		imageBank,
		user,
	};
};

