import { Button, Form, Input, Result, Row, Typography } from 'antd';
import React from 'react';
import SimileLogo from '../../assets/images/simile-logo.svg';
import { useAccountSetup } from './useAccountSetup';
import { Zoom } from 'react-reveal';

const AccountSetup: React.FC = () => {
	const { Text } = Typography;
	const { loadingButton, onFinish } = useAccountSetup();

	return (
		<Zoom>
			<Result
				style={{marginTop: '15%'}}
				icon={<img srcSet={SimileLogo} className="logo-main" alt="Logo" />}
				title="We need to setup your account"
				subTitle="Provide your first and last name to help other members find you"
				extra={
					<React.Fragment>
						<Row justify="center">
							<Form className="login-form"
								initialValues={{ remember: false }}
								onFinish={onFinish}
								onFinishFailed={(errorInfo): void => console.log('Failed:', errorInfo)}
								layout="vertical"
							>
								<Form.Item
									name="name"
									label="Name"
									rules={[{ required: true }]}
								>
									<Input
										placeholder="Name"
									/>
								</Form.Item>
								<Form.Item
									name="lastname"
									label="Last name"
									rules={[{ required: true }]}
								>
									<Input
										placeholder="Last name"
									/>
								</Form.Item>
								<Form.Item>
									<Text type="secondary">This information can only be edited by an administrator,<br/>please verify before continuing</Text>
								</Form.Item>
								<Form.Item>
									<Button
										loading={ loadingButton }
										type="primary"
										htmlType="submit"
										className="login-form-button"
									>
										Go to Analyst Tool
									</Button>
								</Form.Item>
							</Form>
						</Row>
					</React.Fragment>
				}
			/>
		</Zoom>
	);
};

export default AccountSetup;
