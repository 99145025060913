import React from "react";
import { Row, Col, Breadcrumb, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const ProjectHeader = ({name, id}) => {

  return (
    <Row align="middle" justify="space-between">
      <Col>
        <Breadcrumb
          style={{ marginBottom: "20px", marginTop: "20px", fontSize: "30px" }}
        >
          <Breadcrumb.Item>
            <Link to="/">Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col>
          <Link to={`/edit-project/${id}`}>
            <Button type="primary">
              <EditOutlined /> Edit Project
            </Button>          
          </Link>
      </Col>
    </Row>
  );
};

export default ProjectHeader;
