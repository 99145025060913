import { FrownFilled, MehFilled, SmileFilled } from '@ant-design/icons';
import React from 'react';

export const SentimentsType = {
	positive: 'Positive',
	neutral: 'Neutral',
	negative: 'Negative',
};

export const Sentiments = [
	{
		value: SentimentsType.positive,
		icon: <SmileFilled style={{ color: '#4CB865', marginRight: '5px' }} />,
	},
	{
		value: SentimentsType.neutral,
		icon: <MehFilled style={{ color: '#979797', marginRight: '5px'}} />,
	},
	{
		value: SentimentsType.negative,
		icon: <FrownFilled style={{ color: '#E84F3D', marginRight: '5px'}} />,
	},
];
