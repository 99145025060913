import './ResponseIndex.scss';
import { useRemoteConfig, useUser} from 'reactfire';
import { Analyst } from '../../interfaces/Analyst';
import axios from 'axios';
import { Mixpanel } from '../../tools/MixPanel';
import { Pagination } from '../../interfaces/Pagination';
import { Project } from '../../interfaces/Project';
import React from 'react';
import { Response } from '../../interfaces/Response';
import { ResponseFilterContext } from '../../providers/ResponseFilterProvider';
import { useParams } from 'react-router-dom';
import { User } from '../../interfaces/User';

interface UseResponseIndexStore {
	pagination: Pagination;
	loading: boolean;
	project: Project;
	analysts: Analyst[];
	responses: Response[];
	setTotalPages: any;
	setCurrentPage: any;
	setSort: any;
	sort: string;
}

const ResponsePaginationSize = 'responsePaginationSize';
const DefaultPaginationData: Pagination = {
	page: 1,
	size: +(sessionStorage.getItem(ResponsePaginationSize) || 10),
	total: 0,
};

const DefaultProjectData: Project = {
	name: '',
	survey: '',
	surveyId: '',
	totalResponses: 0,
	'simile_index': '',
	'simile_status': '',
	totalComments: 0,
	savedTotalResponses: 0,
	totalCompleted: 0,
	totalDiscard: 0,
	totalCompletedProgress: 0,
	analyst: '',
};

export const useResponseIndex = (): UseResponseIndexStore => {
	const { id }: {id: string} = useParams();
	const userFirebase: User | firebase.User = useUser();
	const remoteConfig = useRemoteConfig();
	const [loading, setLoading] = React.useState(true);
	const [project, setProject] = React.useState<Project>(DefaultProjectData);
	const [responses, setResponses] = React.useState<Response[]>([]);
	const [analysts, setAnalysts] = React.useState<Analyst[]>([]);
	const [pagination, setPagination] = React.useState<Pagination>(DefaultPaginationData);
	const [totalPages, setTotalPages] = React.useState(+(sessionStorage.getItem(ResponsePaginationSize) || 10));
	const [currentPage, setCurrentPage] = React.useState(1);
	const [sort, setSort] = React.useState('Response number');
	const { filters } = React.useContext(ResponseFilterContext);

	remoteConfig.defaultConfig = {
		'function_responses_list_url': 'https://us-central1-simile-analyst-tool.cloudfunctions.net/responsesList',
	};

	remoteConfig.settings = {
		fetchTimeoutMillis: 60000,
		minimumFetchIntervalMillis: 3600000,
	};

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'project-detail',
			projectId: id,
		});
	}, [id]);

	React.useEffect(() => {
		async function fetchData(): Promise<void> {
			setLoading(true);

			try {
				await remoteConfig.fetchAndActivate();
			} catch (err) {
				console.error(err);
				setLoading(false);
			}

			try {
				let remoteUrl = remoteConfig.getString('function_responses_list_url');

				if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
					remoteUrl = 'https://us-central1-simile-analyst-tool.cloudfunctions.net/responsesList';
				}

				const result = await axios(
					remoteUrl+`?surveyId=${id}&size=${totalPages}&page=${currentPage}&sort=${encodeURIComponent(sort)}&filters=${encodeURIComponent(JSON.stringify(filters))}`
				);

				if (!result.data.project.analyst.includes(userFirebase.email)) {
					window.location.replace('/not-found');
				}

				if (typeof result.data.pagination !== 'undefined') {
					sessionStorage.setItem(ResponsePaginationSize, result.data.pagination.size);
					setPagination(result.data.pagination);
				}

				setProject(result.data.project);
				setAnalysts(result.data.analysts);
				setResponses(result.data.responses);
				setLoading(false);

			} catch (err) {
				console.error('Error fetching projects', err);
				setLoading(false);
			}
		}

		fetchData();

	}, [remoteConfig, totalPages, currentPage, sort, filters]);

	return {
		pagination,
		loading,
		project,
		analysts,
		responses,
		setTotalPages,
		setCurrentPage,
		setSort,
		sort,
	};

};
