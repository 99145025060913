import { Breadcrumb, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import ResponseNavigator from '../Response/ResponseNavigator';
import { RightCircleOutlined } from '@ant-design/icons';

const ResponseHeader = (props) => {
	const { response, project, tooglePreview} = props;

	const numberFormater = (number) => {
		if (typeof number === 'undefined' || Number.isNaN(number)) {
			return '000';
		}

		const str = number.toString();

		return str.length < 3 ? numberFormater('0' + str) : str;
	};

	return (
		<Row align="middle" justify="space-between" style={{ position: 'relative'}}>
			<div className="toogle-preview-button">
				<RightCircleOutlined style={{ fontSize: '20px', color: '#979797' }} onClick={tooglePreview}/>
			</div>
			<Col>
				<Breadcrumb
					style={{ marginBottom: '20px', marginTop: '20px', fontSize: '30px' }}
				>
					<Breadcrumb.Item>
						<Link to={'/'}>...</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={`/project/${project.survey}`}>{project.name}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>#{numberFormater(response.simile_index)}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col>
				<ResponseNavigator max={project.totalResponses} current={response.simile_index} project={project.survey} />
			</Col>
		</Row>
	);
};

export default ResponseHeader;
