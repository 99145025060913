import React, { useEffect, useState, useContext } from "react";
import { Comment, Avatar } from "antd";
import moment from 'moment';
import CommentInput from '../CommentInput/CommentInput';
import { useFirestore, useFirestoreCollectionData } from "reactfire"
import { UserContext } from '../../providers/UserProvider';
import CommentList from '../CommentList/CommentList';

const CommentWithInput = ({comment, query}) => {
    
    const firestore = useFirestore()
    const [showInput, setShowInput] = useState(false)
    const { user } = useContext(UserContext)
    const [comments, setComments] = useState([])

    const currentQuery = query.doc(comment.idFirebase).collection("comments")
    //const comments = useFirestoreCollectionData(currentQuery, {idField: 'idFirebase'})

    const getFormatedDate = (time) => {
        return moment.unix(time).format("LLL")
    }

    const toogleInput = () => {
        setShowInput(!showInput)
    }

    const addComment = (inputValue) => {
        currentQuery
        .add({
            author: user.name,
            author_initials: user.initials,
            author_color: user.color,
            content: inputValue,
            datetime: new Date()
        })
        .then(() => {
            console.log("Document written with ID: ", comment.idFirebase)
            loadComments()
        })
        .catch((error) => {
            console.error("Error updating document: ", error)
        })          
    }

    const loadComments = () => {
        currentQuery
        .get()
        .then(function(querySnapshot) {
            const commentsArray = querySnapshot.docs.map(doc => {
                let array = doc.data()
                array.idFirebase = doc.id
                return array
            })

            const sorted = commentsArray.sort(function(a,b){
                return new Date(a.datetime.seconds) - new Date(b.datetime.seconds);
            });

            setComments(sorted)
            setShowInput(false)
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });         
    }
    useEffect(() => {
        loadComments()        
    }, [comment])

    return (
        <Comment
        actions={[<a onClick={toogleInput}>Reply</a>]}
        author={comment.author}
        avatar={<Avatar style={{backgroundColor: comment.author_color}}>{comment.author_initials}</Avatar>}
        content={comment.content}
        datetime={getFormatedDate(comment.datetime.seconds)}>
            {comments.length > 0 &&
                <CommentList query={currentQuery} comments={comments} />                 
            }
            {showInput &&
                <CommentInput addComment={addComment}/>
            }
        </Comment>
    )
};

export default CommentWithInput;
