import React, { useContext, useState } from "react";
import { Dropdown, Input, Typography, Row, Col, Button, Space, Form, Select } from "antd";
import "./ImageRowPanelDetail.scss";
import Moment from 'react-moment'
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import Tags from "../Tags/Tags";
import { useAuth, useFirestore, useFirestoreCollectionData } from 'reactfire';
import { UserContext } from '../../providers/UserProvider';
import { Link } from 'react-router-dom';
import { Parser } from 'json2csv'

const ImageRowPanelDetail = ({image}) => {
  const { Text, Paragraph } = Typography;
  const { TextArea } = Input;
  const auth = useAuth()
  const firestore = useFirestore()
  const { user } = useContext(UserContext)
  const [loadingExportSimile, setLoadingExportSimile] = useState(false)

  const { Option } = Select;

  const {
    notes
  } = image;

  return (
    <div className="PanelResponseIndex">
      <Row>
        <Col span={24}>
          <Text type="secondary" className="subtitle">
            Notes
          </Text>
          <TextArea style={{lineHeight: '1.3em'}} rows={3} value={notes} disabled></TextArea>
        </Col>
      </Row>
    </div>
  );
};

export default ImageRowPanelDetail;
