import React from 'react'
import './ResponsesPreview.scss'
import { Col, Row, Space, Typography } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import PreviewBox from '../PreviewBox/PreviewBox';

const ResponsesPreview = ({responses, show, tooglePreview}) => {
    const { Text } = Typography
    return (
        <div onClick={tooglePreview} className={`response-preview-bg ${show ? 'active' : 'inactive'}`}>
            <div className={`ResponsesPreview ${show ? 'active' : 'inactive'}`}>
                <Row align="center">
                    <Col className='preview-close-header' onClick={tooglePreview}>
                        <Space>
                            <LeftCircleOutlined style={{ fontSize: '20px', color: '#979797' }}/>
                            <Text>Close</Text>
                        </Space>

                    </Col>
                </Row>
                {typeof responses != 'undefined' &&
                    responses.map(response => (
                    <PreviewBox tooglePreview={tooglePreview} response={response}/>
                    ))
                }
            </div>                
        </div>        

   )
}

export default ResponsesPreview
