import './SimileHeader.scss';
import { Avatar, Layout, Menu, Modal, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import SimileLogo from '../../assets/images/simile-logo.svg';
import { User } from '../../interfaces/User';
import { UserContext } from '../../providers/UserProvider';

const SimileHeader: React.FC = () => {
	const history = useHistory();
	const { Header } = Layout;
	const { SubMenu } = Menu;
	const { Text } = Typography;
	const { user } = React.useContext(UserContext);
	const auth = useAuth();
	const userFirebase: User | firebase.User = useUser();
	const [visibleModal, setVisibleModal] = React.useState(false);

	const userName = (): JSX.Element => {
		return <div >
			{user !== null && (
				<>
					<Avatar size="small" style={{ backgroundColor: user.color ?? '#c1c1c1' }}>{user.initials ?? 'NA'}</Avatar> {user.name}
				</>
			)}
		</div>;
	};

	React.useEffect(() => {
		if (userFirebase === null) {
			history.push('/login');
		}
	}, [history, userFirebase]);

	const logOut = (): void => {
		Mixpanel.track('Succesful logout', {});
		auth.signOut();
	};

	const goToHome = (): void => {
		const edited = localStorage.getItem('projectEdited');

		if (edited === 'true') {
			setVisibleModal(true);
		} else {
			history.push('/');
		}

	};

	const handleOk = (): void => {
		localStorage.setItem('projectEdited', false.toString());
		history.push('/');
	};

	const handleCancel = (): void => {
		setVisibleModal(false);
	};

	const handleImageBankClick = (): void => {
		history.push('/image-bank');
	};

	const handleMetaphorClassificationClick = (): void => {
		history.push('/metaphor-classification');
	};

	const handleProjects = (): void => {
		history.push('/');
	};

	const getCurrent = (): string[] => {
		const location = window.location.href;

		return location.includes('image-bank') ? ['2'] : location.includes('metaphor-classification') ? ['3'] : ['1'];
	};

	return (
		<Header className="page-header">
			<div className="logo">
				<Link
					to='/'
					onClick={goToHome}>
					<img className="simile-logo" srcSet={SimileLogo} alt="Logo"></img>
				</Link>
			</div>
			<div className="brand">
				<div className="separator-brand"></div>
				<Link to="/">
					<Text>Analyst Tool</Text>
				</Link>
			</div>

			<Menu style={{ float: 'right' }} mode="horizontal">
				<SubMenu title={userName()}>
					<Menu.Item onClick={logOut} key="logout">Logout</Menu.Item>
				</SubMenu>
			</Menu>

			<Menu className="menu-navigation" style={{ width: '380px', margin: '0 auto' }} selectedKeys={getCurrent()} mode="horizontal">
				<Menu.Item key="1" onClick={handleProjects}>Projects</Menu.Item>
				<Menu.Item key="2" onClick={handleImageBankClick}>Image Bank</Menu.Item>
				<Menu.Item key="3" onClick={handleMetaphorClassificationClick}>Metaphor Classification</Menu.Item>
			</Menu>

			<Modal
				title="Are you sure you want to leave this page?"
				visible={visibleModal}
				onOk={handleOk}
				onCancel={handleCancel}
				cancelText="Stay on this page"
				okText="Leave this page"
			>
				<p>The changes you made won`&apos;`t be saved.</p>
			</Modal>
		</Header>
	);
};

export default SimileHeader;
