
import { Button, Checkbox, Col, Form, Input, Layout, Modal, Radio, Row, Space, Typography } from 'antd';
import React from 'react';
import SimileHeader from '../../components/Header/SimileHeader';
import { useEditImage } from './useEditImage';
import { withRouter } from 'react-router';

const EditImage: React.FC = () => {
	const { Content } = Layout;
	const { Title, Text } = Typography;
	const { TextArea } = Input;
	const {
		onFinish,
		setLetters,
		deleteImageBank,
		onCancel,
		loadingButton,
		visibleModal,
		handleOk,
		handleCancel,
		letters,
		questions,
		image,
	} = useEditImage();

	return (
		<Layout className="layout" style={{ paddingTop: '64px'}}>
			<SimileHeader />
			<Content className='container'>
				<Title style={{ marginBottom: '24px', marginTop: '28px', textAlign:'center' }} level={2}>Edit this image bank</Title>

				<Form
					name="basic"
					initialValues={{ name: image.name, notes: image.notes }}
					onFinish={onFinish}
					onFinishFailed={(errorInfo): void => console.error('Failed:', errorInfo)}
				>

					<div className="box-content container" style={{ minHeight: '420px'}}>
						<Row>
							<Col span={12} offset={6}>

								<Space size={0} direction="vertical" style={{ marginBottom: '14px', marginTop:'43px'}}>
									<Text strong><span className="star">*</span> Image bank name</Text>
								</Space>
								<Form.Item
									name="name"
									rules={[{ required: true, message: 'Please input the image bank name' }]}
								>
									<Input style={{ marginTop:'6px'}} placeholder="Placeholder" />
								</Form.Item>
							</Col>
						</Row >
						<Row>
							<Col span={12} offset={6}>
								<Text strong> Image bank notes </Text>
								<Form.Item
									name="notes"
									rules={[{min: 0, max: 200}]}
								>
									<TextArea
										style={{marginTop:'6px', height:'106px', borderRadius: '6px'}}
										className="textArea-Input"
										maxLength={200}
										onChange={(e): void=>setLetters(e.target.value.length)}
									/>
								</Form.Item>
								<Text
									style={{ marginTop: '-25px', marginBottom: '25px'}}
									className='letterCount'
									strong > <span>200 character limit</span> {letters} <span>/200</span></Text>
							</Col>
						</Row>
						<Row>
							<Col span={12} offset={6}>
								<Form.Item
									name="default"
									rules={[{min: 0, max: 200}]}
								>
									<Checkbox>Make default image bank</Checkbox>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={12} offset={6}>
								<Button onClick={deleteImageBank}>Delete this image bank</Button>
							</Col>
						</Row>
					</div>
					<Row justify="center" style={{marginTop:'30px', textAlign: 'center'}}>
						<Col span={2}><Button onClick={onCancel} type="link">Cancel</Button></Col>
						<Col span={2}><Button loading={loadingButton} type="primary" htmlType="submit">Save image bank</Button></Col>
					</Row>
				</Form>
			</Content>

			<Modal
				title="Are you sure you want to leave this page?"
				visible={visibleModal}
				onOk={handleOk}
				onCancel={handleCancel}
				cancelText="Stay on this page"
				okText="Leave this page"
			>
				<p>{'The changes you made won\'t be saved.'}</p>
			</Modal>
		</Layout>
	);
};

export default withRouter(EditImage);
