import firebase from 'firebase';
import { Mixpanel } from '../../tools/MixPanel';
import React from 'react';
import { useFirestore } from 'reactfire';

interface UseImageBanksListStore {
    images: firebase.firestore.DocumentData[];
    loading: boolean;
}

export const useImageBanksList = (): UseImageBanksListStore => {
	const [images, setImages] = React.useState<firebase.firestore.DocumentData[]>([]);
	const [loading, setLoading] = React.useState(true);

	const firestore = useFirestore();

	React.useEffect(() => {
		Mixpanel.track('view', {
			id: 'project-index',
		});
	}, []);

	React.useEffect(() => {
		firestore.collection('images')
			.get()
			.then( async (querySnapshot) => {
				const projectsArray = querySnapshot.docs.map(doc => {
					const array = doc.data();

					array.idFirebase = doc.id;

					return array;
				});

				for (let index = 0; index < projectsArray.length; index++) {
					const queryBank = firestore.collection('images').doc(projectsArray[index].idFirebase).collection('bank');
					const bankSnapshot = await queryBank.get();
					const bankArray = bankSnapshot.docs.map(doc => {
						const array = doc.data();

						return array;
					});

					projectsArray[index].bank = bankArray;
				}

				const sorted = projectsArray.sort((a: firebase.firestore.DocumentData, b: firebase.firestore.DocumentData) => {
					return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
				});

				setImages(sorted);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error getting documents: ', error);
			});
	}, [firestore]);

	return{
		images,
		loading,
	};
};
